import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getMeApi, login, sendLoginOTPApi } from 'api/auth'
import { createCallApiAsyncAction } from 'store/middlewares/createAsyncAction'
import { IUserInfo } from 'types/auth'
import { fetchFirebaseConfigApi } from 'config/firebase'
export const TYPE = 'AUTH'

export const runLogin = createCallApiAsyncAction(TYPE + '/auth', login)

export const sendLoginOTP = createCallApiAsyncAction(
  TYPE + '/sendLoginOTP',
  sendLoginOTPApi,
)
export const fetchMeInfo = createCallApiAsyncAction(
  TYPE + '/fetchMeInfo',
  getMeApi,
)

export const fetchFirebaseConfig = createCallApiAsyncAction(
  TYPE + '/fetchFirebaseConfig',
  fetchFirebaseConfigApi,
)

interface IAuthState {
  userInfo: IUserInfo
  token?: string
  isLoading?: boolean
  error?: string
  firebaseConfig: {
    sharedSecret?: string
  }
}

const initialState: IAuthState = {
  userInfo: {},
  token: '',
  isLoading: false,
  error: '',
  firebaseConfig: {
    sharedSecret: '',
  },
}

const authSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setUserToken(state, action: PayloadAction<string>) {
      state.isLoading = false
      state.error = ''
      state.token = action.payload
    },
    removeUserToken(state) {
      state.isLoading = false
      state.error = ''
      state.token = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFirebaseConfig.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.firebaseConfig = response || {}
      }
    })
    builder.addCase(runLogin.fulfilled, (state, action) => {
      const { response } = action.payload

      state.isLoading = false
      if (response.apiStatus) {
        if (response?.accessToken) {
          state.token = response?.accessToken
        }
      } else {
        state.error = response.message?.toString()
      }
    })
    builder.addCase(runLogin.pending, (state) => {
      state.error = ''
      state.isLoading = true
    })

    builder.addCase(fetchMeInfo.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.userInfo = response
      }
    })
    builder.addCase(fetchMeInfo.pending, (state) => {})
  },
})

export const { setUserToken, removeUserToken } = authSlice.actions
export default authSlice.reducer
