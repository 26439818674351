import { css } from '@emotion/react'

const styles = {
  wrapper: css`
    margin: 0 auto 16px;
    max-width: 1000px;
    svg path {
      stroke: #979797;
    }
  `,
  item: css`
    color: #979797;
    text-decoration: none;
  `,
  lastItem: css`
    color: #fff;
  `,
}

export default styles
