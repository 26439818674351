/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'iconsax-react'
import {
  fetchIndoorShoeBoxOnchainBalance,
  fetchShoeBoxOnchainBalance,
} from 'store/reducers/walletOnchain'
import Images from 'images'
import { useAppDispatch, useAppSelector } from 'store'
import { commonClass } from 'theme'
import { SOURCES } from 'types/common'
import ShoeboxCard from './ShoeboxCard'
import styles from './NFTShoeboxList.styles'

const NFTShoeboxList = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const walletOnchain = useAppSelector((state) => state.walletOnchain)
  const appSource = useAppSelector((state) => state.common.source) || 'OUTDOOR'

  useEffect(() => {
    if (account && appSource === SOURCES.OUTDOOR) {
      dispatch(fetchShoeBoxOnchainBalance(account))
    }
    if (account && appSource === SOURCES.INDOOR) {
      dispatch(fetchIndoorShoeBoxOnchainBalance(account))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, appSource])

  return (
    <div css={[commonClass.containerSmall, styles.wrapper]}>
      <div css={styles.pageHeader}>
        <Link to='/wallet/onchain' css={styles.iconBack}>
          <ArrowLeft size='24' color='#D0E0F7' className='spending' />
        </Link>
        <h3>Shoeboxes</h3>
      </div>

      {walletOnchain?.shoebox?.length > 0 ? (
        <div css={styles.contentWrapper}>
          {walletOnchain?.shoebox?.map((sb) => (
            <ShoeboxCard
              image={sb.image}
              name={sb.name}
              shoeBoxId={sb.shoeBoxId}
              quality={sb.quality}
            />
          ))}{' '}
        </div>
      ) : (
        <div css={styles.noItem}>
          <img src={Images.Empty.Shoebox} alt='No Shoebox' />
          <div css={styles.noItemText}>No items in bags.</div>
        </div>
      )}
    </div>
  )
}

export default NFTShoeboxList
