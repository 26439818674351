/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ArrowLeft } from 'iconsax-react'
import _get from 'lodash/get'
import {
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
  Typography,
} from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { baseColors, commonClass } from 'theme'
import { useAppDispatch, useAppSelector } from 'store'
import { getShoeboxDetail } from 'api/wallet'
import { IShoeBox } from 'types/nft'
import { capitalizeFirstLetter } from 'helpers/utils'
import {
  getIndoorShoeboxContract,
  getShoeboxContract,
} from 'utils/contractHelpers'
import { showToast } from 'store/reducers/common'
import { ROUTE_PATHS } from 'routes'
import { SOURCES } from 'types/common'
import styles from './NFTTransferExternal'
import NFTTransferConfirmModal from './NFTTransferConfirmModal'

const ShoeboxTransferExternal = () => {
  const navigate = useNavigate()
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()
  const { shoeBoxId } = useParams()
  const appSource = useAppSelector((state) => state.common.source) || 'OUTDOOR'

  const [data, setData] = useState<IShoeBox>()
  const [address, setAddress] = useState<string>('')
  const [owner, setOwner] = useState('')

  const [loading, setLoading] = useState(false)
  const [loadingConfirm, setLoadingConfirm] = useState(false)
  const [isOpenConfirm, setIsOpenConfirm] = useState(false)

  const [transferData, setTransferData] = useState<any>()

  const qualityText = capitalizeFirstLetter(data?.quality)
  const qualityColor = _get(baseColors, qualityText + 'Color')

  const isAddress = ethers.utils.isAddress(address)

  const getCurrentContract = (signer?: any) => {
    let contract = null

    switch (appSource) {
      case SOURCES.OUTDOOR:
        contract = getShoeboxContract(signer)
        break
      case SOURCES.INDOOR:
        contract = getIndoorShoeboxContract(signer)
        break
      default:
        contract = null
    }

    return contract
  }

  const getShoeboxInfo = async () => {
    const res = await getShoeboxDetail(shoeBoxId)

    setData(res)
  }

  const getNFTOwner = async () => {
    const contract = getCurrentContract()

    if (contract) {
      const tx = await contract.ownerOf(shoeBoxId)

      setOwner(tx)
    }
  }

  const getFeeTransaction = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()

    try {
      const contract = getCurrentContract()
      const txGasPrice = await signer.getGasPrice()

      if (contract) {
        const txGasLimit = await contract.estimateGas.transferFrom(
          account,
          address?.trim(),
          shoeBoxId,
        )
        const transactionFee = +txGasPrice * +txGasLimit

        return ethers.utils.formatEther(transactionFee)
      }
    } catch (error) {
      throw error
    }
  }

  const handleConfirmFee = async () => {
    setLoadingConfirm(true)
    try {
      const transactionFee = await getFeeTransaction()

      setTransferData({
        recipient: address,
        fee: transactionFee,
      })
      setIsOpenConfirm(true)
      setLoadingConfirm(false)
    } catch (error: any) {
      setLoadingConfirm(false)
      dispatch(
        showToast({
          title: error?.message || 'Error, please try again',
        }),
      )
    }
  }

  const handleTransferNFT = async () => {
    setLoading(true)
    const { recipient } = transferData
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const contract = getCurrentContract(signer)

    try {
      const sender = account

      if (contract) {
        const tx = await contract.transferFrom(sender, recipient, shoeBoxId)

        await tx.wait()
        setIsOpenConfirm(false)
        setLoading(false)
        dispatch(
          showToast({
            title:
              'Transaction has submitted. This progress will take (5-10 minutes) to finish.',
          }),
        )
        navigate(ROUTE_PATHS.WALLET_ONCHAIN)
      }
    } catch (error: any) {
      setLoading(false)
      dispatch(
        showToast({
          title: error?.message || 'Error, please try again',
        }),
      )
    }
  }

  useEffect(() => {
    getShoeboxInfo()
    getNFTOwner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoeBoxId])

  return (
    <div css={[commonClass.containerSmall, styles.wrapper]}>
      {owner === '' && null}
      {account === owner && (
        <div css={styles.contentWrapper}>
          <div css={styles.pageHeader}>
            <Link to='/wallet/shoebox' css={styles.iconBack}>
              <ArrowLeft size='24' color='#D0E0F7' className='spending' />
            </Link>
            <h3>Send to</h3>
          </div>
          <div css={styles.nftImgWrapper}>
            <img css={styles.nftImg} src={data?.image} alt='icon' />
            <Typography color={qualityColor} css={styles.id}>
              #{shoeBoxId}
            </Typography>
          </div>
          <div css={styles.formWrapper}>
            <FormControl fullWidth>
              <div css={styles.formLabel}>To address</div>
              <OutlinedInput
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                css={styles.inputWrapper}
              />
            </FormControl>
          </div>
          <Button
            onClick={handleConfirmFee}
            css={[commonClass.appButton, styles.confirmBtn]}
            disabled={loadingConfirm || !isAddress}
          >
            <div className='dfc'>
              {loadingConfirm && <CircularProgress size={18} />}
              <span>CONFIRM</span>
            </div>
          </Button>
        </div>
      )}
      {account !== owner && (
        <div css={styles.contentWrapper}>
          <h3>You are not owned this NFT</h3>
        </div>
      )}
      {transferData && (
        <NFTTransferConfirmModal
          isOpen={isOpenConfirm}
          onClose={() => setIsOpenConfirm(false)}
          isLoading={loading}
          transferData={transferData}
          onConfirm={handleTransferNFT}
        />
      )}
    </div>
  )
}

export default ShoeboxTransferExternal
