import { css } from '@emotion/react'
import { baseColors, mobileWidth } from 'theme'

const styles = {
  wrapper: css`
    box-sizing: border-box;
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    * {
      box-sizing: border-box;
    }
  `,
  header: css`
    background: #242634;
    padding: 12px 18px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #1a1c2c;
  `,
  save: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.4px;
    cursor: pointer;
    margin: 0;
    color: #ff7510;
  `,
  headerTitle: css`
    cursor: pointer;
    font-weight: 500;
    flex: 1;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #f5f5f5;
    margin: 0;
  `,
  content: css`
    overflow: auto;
    max-height: 400px;
  `,
  title: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 0 24px;
    span {
      color: ${baseColors.darkPrimary};
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  `,
  number2: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #ffffff;
  `,
  number: css`
    background: #1b2030;
    border-radius: 2px;

    display: block;

    height: 16px;
    min-width: 16px;

    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;

    color: #ff7510;
  `,
  label: css`
    gap: 8px;
    letter-spacing: 0.4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    color: #596c94;
    padding: 10px 16px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,

  item: css`
    border-bottom: 1px solid #1a1c2c;
  `,
  selectList: css`
    background: #202230;
  `,
  selectItem: css`
    border-radius: 30px;
    padding: 8px 16px;
    cursor: pointer;
    user-select: none;

    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;

    display: flex;
    align-items: center;
    gap: 12px;

    color: #ffffff;
    @media only screen and (min-width: ${mobileWidth}px) {
      font-size: 16px;
    }
  `,
  selectItemActive: css`
    background: ${baseColors.primaryColor};
  `,

  actions: css`
    display: flex;
    align-items: center;
    padding: 12px;
  `,
  actionClear: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    margin: 4px 8px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    padding: 0;
    cursor: pointer;
  `,
  actionSave: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    margin: 4px 8px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    padding: 0;
    cursor: pointer;
  `,

  slider: css`
    background: #202230;
    padding: 12px 16px 0 16px;
  `,
  minMax: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;

      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #f2f2f2;
    }
  `,
}

export default styles
