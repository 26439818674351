import { css } from '@emotion/react'

import { baseColors, tabletWidth } from 'theme'
const styles = {
  container: css`
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  `,
  wrapper: css`
    display: flex;
  `,
  filterMobileWrapper: css`
    background-color: #242634;
    width: 220px;
    height: auto;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 10;
  `,
  filterWrapper: css`
    display: none;
    @media only screen and (min-width: ${tabletWidth}px) {
      display: block;
      background-color: ${baseColors.cardBg};
      width: 320px;
      min-width: 320px;
    }
  `,
  contentWrapper: css`
    padding: 0;
    padding-top: 16px;
    @media only screen and (max-width: ${tabletWidth}px) {
      padding: 16px;
      padding-bottom: 0;
    }
    flex: 1;
  `,
  topWrapper: css`
    @media only screen and (min-width: ${tabletWidth}px) {
      display: flex;
      justify-content: center;
      position: relative;
    }
  `,
  listActionWrap: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    @media only screen and (min-width: ${tabletWidth}px) {
      position: absolute;
      right: 0;
      margin-bottom: 0;
      top: 15px;
    }
  `,
  filterButton: css`
    position: relative;
  `,
  scrollList: css`
    position: relative;
    height: calc(100vh - 129px);
    overflow: auto;

    @media only screen and (max-width: ${tabletWidth}px) {
      height: calc(100vh - 235px);
    }
  `,
  empty: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    img {
      width: 300px;
      height: 300px;
    }
  `,
  loading: css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0005;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
}

export default styles
