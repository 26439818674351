/** @jsxImportSource @emotion/react */
import { Modal, Box, CircularProgress, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { baseColors, commonClass } from 'theme'
import { runLogin, sendLoginOTP, setUserToken } from 'store/reducers/auth'
import { ILoginRequest } from 'types/auth'
import { useAppDispatch, useAppSelector } from 'store'
import { validateEmail } from 'helpers/utils'
import { showToast } from 'store/reducers/common'
import styles from './login.styles'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: baseColors.cardBg,
  borderRadius: 2,
  outline: 'none',
}

interface ModalLoginProps {
  open: boolean
  handleClose: Function
}

interface ILoginData {
  email?: string
  code?: string
  password?: string
}

interface ILoginError {
  email?: string
  code?: string
  password?: string
}

let interval: string | number | NodeJS.Timer | undefined
const MODE = {
  code: 'CODE',
  password: 'PASSWORD',
}
const ModalLogin = ({ open, handleClose }: ModalLoginProps) => {
  const [mode, setMode] = useState(MODE.password)
  const [loadingCode, setLoadingCode] = useState(false)
  const [authToken, setAuthToken] = useState('')
  const [data, setData] = useState<ILoginData>({})
  const [error, setError] = useState<ILoginError>({})
  const [otpRequire, setOtpRequire] = useState(false)

  const [time, setTime] = useState(60)
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector((state) => state.auth.isLoading)
  const errorText = useAppSelector((state) => state.auth.error)
  const currToken = useAppSelector((state) => state.auth.token)

  useEffect(() => {
    if (!currToken) {
      setData({})
      setError({})
      setOtpRequire(false)
    }
  }, [currToken])

  const valiedateCode = () => {
    const e: ILoginData = {}
    let hasError = false

    if (!data.email) {
      e.email = 'Email is required'
      hasError = true
    }
    if (!validateEmail(data.email || '')) {
      e.email = 'Invalid Email format'
      hasError = true
    }

    if (!data.code) {
      e.code = 'Code is required'
      hasError = true
    }

    setError(e)

    return hasError
  }
  const validate = () => {
    const e: ILoginData = {}
    let hasError = false

    if (!data.email) {
      e.email = 'Email is required'
      hasError = true
    }

    if (!validateEmail(data.email || '')) {
      e.email = 'Invalid Email format'
      hasError = true
    }

    if (!data.password) {
      e.password = 'Password is required'
      hasError = true
    }
    setError(e)

    return hasError
  }

  const login = () => {
    if (mode === MODE.code) {
      if (valiedateCode()) {
        return
      }

      const dataSend: ILoginRequest = {
        email: data.email || '',
        verifyCode: data.code || '',
        authToken: authToken || '',
        // password: data.password || '',
        type: 'LOGIN',
      }

      dispatch(runLogin(dataSend))
    } else {
      if (validate()) {
        return
      }

      const dataSend: ILoginRequest = {
        email: data.email || '',
        password: data.password || '',
      }

      dispatch(runLogin(dataSend))
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            if (response.accessToken) {
              dispatch(setUserToken(response.accessToken))
            } else if (response.otpRequire && response.authToken) {
              setOtpRequire(true)
              dispatch(
                showToast({
                  title: 'OTP is required',
                  message: 'We have sent you an OTP in your email.',
                }),
              )
              setMode(MODE.code)
              setTime(59)
              setAuthToken(response.authToken)
              interval = setInterval(() => {
                setTime((a) => a - 1)
              }, 1000)
            } else {
              setAuthToken('')
            }
          }
        })
        .catch(() => {
          setAuthToken('')
        })
    }
  }

  const sendCode = () => {
    setError({})
    if (!data.email) {
      setError({ email: 'Email is required' })

      return
    }
    if (!validateEmail(data.email || '')) {
      setError({ email: 'Invalid Email format' })

      return
    }
    setTime(59)
    setLoadingCode(true)
    dispatch(sendLoginOTP({ email: data.email }))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus && response.authToken) {
          setAuthToken(response.authToken)
          interval = setInterval(() => {
            setTime((a) => a - 1)
          }, 1000)
        } else {
          setAuthToken('')
        }
        setLoadingCode(false)
      })
      .catch((e) => {
        setAuthToken('')
        setLoadingCode(false)
      })

    return () => {
      clearInterval(interval)
    }
  }

  useEffect(() => {
    if (time <= 0) {
      clearInterval(interval)
    }
  }, [time])

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        login()
      }
    }

    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])

  return (
    <Modal open={open}>
      <Box sx={style}>
        <div css={styles.box}>
          <p css={styles.title}>Login</p>
          <div css={styles.inputWrap}>
            <input
              placeholder='Email address'
              value={data.email}
              disabled={otpRequire}
              type='email'
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            {error.email && <p css={styles.errorText}>{error.email} </p>}
          </div>
          {mode === MODE.code && (
            <div css={styles.inputWrap}>
              <input
                placeholder='Email Verify Code'
                type='password'
                value={data.code}
                onChange={(e) => setData({ ...data, code: e.target.value })}
              />

              {error.code && <p css={styles.errorText}>{error.code} </p>}
              {time > 0 && time < 60 ? (
                <p css={styles.sendCode}>{time}</p>
              ) : loadingCode ? (
                <CircularProgress size={16} css={styles.sendCode} />
              ) : (
                <a css={styles.sendCode} onClick={() => sendCode()}>
                  Send Code
                </a>
              )}
            </div>
          )}
          {mode === MODE.password && (
            <div css={styles.inputWrap}>
              <input
                placeholder='Password'
                type='password'
                value={data.password}
                onChange={(e) => setData({ ...data, password: e.target.value })}
              />
              {error.password && (
                <p css={styles.errorText}>{error.password} </p>
              )}
            </div>
          )}

          <Button
            onClick={login}
            css={[commonClass.appButton]}
            style={{ minWidth: '150px' }}
          >
            LOGIN
          </Button>
          {errorText && <p css={styles.errorText}>{errorText} </p>}
          {!otpRequire && (
            <>
              {mode === MODE.code && (
                <p css={styles.textBtn} onClick={() => setMode(MODE.password)}>
                  Login with password
                </p>
              )}
              {mode === MODE.password && (
                <p css={styles.textBtn} onClick={() => setMode(MODE.code)}>
                  Login with code
                </p>
              )}
            </>
          )}
        </div>
        {isLoading && (
          <div css={styles.loading}>
            <CircularProgress />
          </div>
        )}
      </Box>
    </Modal>
  )
}

export default ModalLogin
