/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'iconsax-react'
import TransferSwitchDirection from 'components/transfer-switch-direction/TransferSwitchDirection'
import { fetchWalletBallance } from 'store/reducers/wallet'
import {
  fetchBNBBalance,
  fetchCaloOnchainBallance,
  fetchFitOnchainBallance,
  fetchShoeOnchainBalance,
  fetchShoeBoxOnchainBalance,
  fetchIFitOnchainBalance,
  fetchIndoorShoeOnchainBalance,
  fetchIndoorShoeBoxOnchainBalance,
} from 'store/reducers/walletOnchain'
import { useAppDispatch, useAppSelector } from 'store'
import { SOURCES, TRANSFER_DIRECTION } from 'types/common'
import { commonClass } from 'theme'
import Deposit from './Deposit'
import Withdraw from './Withdraw'
import styles from './Transfer.styles'

const TransferWallet = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const accessToken = useAppSelector((state) => state.auth.token)
  const appSource = useAppSelector((state) => state.common.source) || 'OUTDOOR'

  const [direction, setDirection] = useState(TRANSFER_DIRECTION.DEPOSIT)

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchWalletBallance({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  useEffect(() => {
    if (account) {
      dispatch(fetchCaloOnchainBallance(account))
      dispatch(fetchBNBBalance(account))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    if (account && appSource === SOURCES.OUTDOOR) {
      dispatch(fetchFitOnchainBallance(account))
      dispatch(fetchShoeOnchainBalance(account))
      dispatch(fetchShoeBoxOnchainBalance(account))
    }
    if (account && appSource === SOURCES.INDOOR) {
      dispatch(fetchIFitOnchainBalance(account))
      dispatch(fetchIndoorShoeOnchainBalance(account))
      dispatch(fetchIndoorShoeBoxOnchainBalance(account))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, appSource])

  const handleSwitchDirection = () => {
    const drtValue =
      direction === TRANSFER_DIRECTION.DEPOSIT
        ? TRANSFER_DIRECTION.WITHDRAW
        : TRANSFER_DIRECTION.DEPOSIT

    setDirection(drtValue)
  }

  return (
    <div css={[commonClass.containerSmall, styles.wrapper]}>
      <div css={styles.contentWrapper}>
        <div css={styles.pageHeader}>
          <Link to='/wallet/onchain' css={styles.iconBack}>
            <ArrowLeft size='24' color='#D0E0F7' className='spending' />
          </Link>
          <h3>Transfer</h3>
        </div>
        <div>
          <TransferSwitchDirection
            direction={direction}
            switchDirection={handleSwitchDirection}
          />
        </div>

        {direction === TRANSFER_DIRECTION.DEPOSIT && <Deposit />}
        {direction === TRANSFER_DIRECTION.WITHDRAW && <Withdraw />}
      </div>
    </div>
  )
}

export default TransferWallet
