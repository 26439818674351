/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { MaxUint256 } from '@ethersproject/constants'
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
  CircularProgress,
} from '@mui/material'
import { Global } from '@emotion/react'
import { SelectChangeEvent } from '@mui/material/Select'
import { formatNumber } from 'helpers/formatBalance'
import {
  useCaloContract,
  useFitContract,
  useShoeContract,
  useShoeboxContract,
  useIFitContract,
  useIndoorShoeContract,
  useIndoorShoeboxContract,
} from 'hooks/useContract'
import { ethersToSerializedBigNumber } from 'utils/bigNumber'
import { TOKEN_TYPE, NFT_TYPE, NFT_STATUS, SOURCES } from 'types/common'
import { commonClass } from 'theme'
import {
  fetchBNBBalance,
  fetchCaloOnchainBallance,
  fetchFitOnchainBallance,
  fetchShoeOnchainBalance,
  fetchShoeBoxOnchainBalance,
} from 'store/reducers/walletOnchain'
import {
  depositCaloFit,
  depositCaloIFit,
  depositShoe,
  depositShoebox,
  depositShoeboxIndoor,
  depositShoeIndoor,
} from 'api/smc'
import { useAppDispatch, useAppSelector } from 'store'
import Images from 'images'
import { showToast } from 'store/reducers/common'
import DepositConfirmModal from './DepositConfirmModal'

import styles from './Transfer.styles'

interface ITokenSelect {
  type: string
  image?: string
  id?: number
  shoe_id?: number
  shoeBoxId?: number
}

const Deposit = () => {
  const caloContract = useCaloContract()
  const fitContract = useFitContract()
  const ifitContract = useIFitContract()
  const shoeContract = useShoeContract()
  const shoeboxContract = useShoeboxContract()
  const indoorShoeContract = useIndoorShoeContract()
  const indoorShoeboxContract = useIndoorShoeboxContract()
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const userInfo = useAppSelector((state) => state.auth.userInfo)
  const walletOnchain = useAppSelector((state) => state.walletOnchain)
  const appSource = useAppSelector((state) => state.common.source) || 'OUTDOOR'

  const { shoe: shoeList = [], shoebox: shoeboxList = [] } = walletOnchain

  const [isOpenConfirm, setIsOpenConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [isApproved, setIsApproved] = useState(true)
  const [tokenBalance, setTokenBalance] = useState(0)
  const [tokenSelected, setTokenSelected] = useState<ITokenSelect>()
  const [amount, setAmount] = useState<string>()

  const getCurrentContract = (tokenSelected: string) => {
    let contract = null

    switch (true) {
      case tokenSelected === TOKEN_TYPE.CALO:
        contract = caloContract
        break
      case tokenSelected === TOKEN_TYPE.FIT:
        contract = fitContract
        break
      case tokenSelected === TOKEN_TYPE.IFIT:
        contract = ifitContract
        break
      case tokenSelected === NFT_TYPE.SHOE && appSource === SOURCES.OUTDOOR:
        contract = shoeContract
        break
      case tokenSelected === NFT_TYPE.SHOEBOX && appSource === SOURCES.OUTDOOR:
        contract = shoeboxContract
        break
      case tokenSelected === NFT_TYPE.SHOE && appSource === SOURCES.INDOOR:
        contract = indoorShoeContract
        break
      case tokenSelected === NFT_TYPE.SHOEBOX && appSource === SOURCES.INDOOR:
        contract = indoorShoeboxContract
        break
      default:
        contract = null
    }

    return contract
  }

  const getContractAddress = (key: string) => {
    let address = null

    switch (true) {
      case key === 'token' && appSource === SOURCES.OUTDOOR:
        address = process.env.REACT_APP_DEPOSIT_WITHDRAW_TOKEN_CONTRACT_ADDRESS
        break
      case key === 'depShoe' && appSource === SOURCES.OUTDOOR:
        address = process.env.REACT_APP_DEPOSIT_WITHDRAW_SHOE_CONTRACT_ADDRESS
        break
      case key === 'depShoebox' && appSource === SOURCES.OUTDOOR:
        address =
          process.env.REACT_APP_DEPOSIT_WITHDRAW_SHOEBOX_CONTRACT_ADDRESS
        break
      case key === 'token' && appSource === SOURCES.INDOOR:
        address =
          process.env.REACT_APP_INDOOR_DEPOSIT_WITHDRAW_TOKEN_CONTRACT_ADDRESS
        break
      case key === 'depShoe' && appSource === SOURCES.INDOOR:
        address =
          process.env.REACT_APP_INDOOR_DEPOSIT_WITHDRAW_SHOE_CONTRACT_ADDRESS
        break
      case key === 'depShoebox' && appSource === SOURCES.INDOOR:
        address =
          process.env.REACT_APP_INDOOR_DEPOSIT_WITHDRAW_SHOEBOX_CONTRACT_ADDRESS
        break

      default:
        address = null
    }

    return address
  }

  const checkApproveToken = async (
    contract: any,
    spender: string,
    walletAddress: string,
  ) => {
    const allowance = await contract.allowance(walletAddress, spender)
    const totalApproved = parseFloat(ethersToSerializedBigNumber(allowance))

    setIsApproved(totalApproved > 0)
  }

  const checkApproveNFT = async (
    contract: any,
    spender: string,
    walletAddress: string,
  ) => {
    const isApproved = await contract.isApprovedForAll(walletAddress, spender)

    setIsApproved(isApproved)
  }

  useEffect(() => {
    if (account && tokenSelected && checkApproveToken && checkApproveNFT) {
      const contract = getCurrentContract(tokenSelected.type)

      if (tokenSelected.type === NFT_TYPE.SHOE) {
        checkApproveNFT(
          contract,
          getContractAddress('depShoe') as string,
          account,
        )
      } else if (tokenSelected.type === NFT_TYPE.SHOEBOX) {
        checkApproveNFT(
          contract,
          getContractAddress('depShoebox') as string,
          account,
        )
      } else {
        checkApproveToken(
          contract,
          getContractAddress('token') as string,
          account,
        )
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, tokenSelected, appSource, checkApproveToken, checkApproveNFT])

  const handleSetTokenBalance = (token: ITokenSelect) => {
    let balance = 0

    switch (token.type) {
      case TOKEN_TYPE.CALO:
        balance = walletOnchain.calo || 0
        break
      case TOKEN_TYPE.FIT:
        balance = walletOnchain.fit || 0
        break
      case TOKEN_TYPE.IFIT:
        balance = walletOnchain.ifit || 0
        break
      default:
        balance = 1
    }

    setTokenBalance(balance)
  }

  const handleTokenChange = (event: SelectChangeEvent) => {
    const token = JSON.parse(event.target.value) || {}

    if (token.type === NFT_TYPE.SHOE || token.type === NFT_TYPE.SHOEBOX) {
      setAmount('1')
    } else {
      setAmount('')
    }

    handleSetTokenBalance(token)
    setTokenSelected(token)
  }

  const handleConfirmTransferOutdoor = async () => {
    if (account && !!userInfo.caloId && tokenSelected && amount) {
      setLoading(true)
      try {
        let tx

        if (tokenSelected.type === NFT_TYPE.SHOE && tokenSelected.shoe_id) {
          tx = await depositShoe(
            account,
            userInfo?.caloId,
            tokenSelected.type,
            tokenSelected.shoe_id,
          )
        } else if (
          tokenSelected.type === NFT_TYPE.SHOEBOX &&
          tokenSelected.shoeBoxId
        ) {
          tx = await depositShoebox(
            account,
            userInfo?.caloId,
            tokenSelected.type,
            tokenSelected.shoeBoxId,
          )
        } else {
          tx = await depositCaloFit(
            account,
            userInfo?.caloId,
            tokenSelected.type,
            amount,
          )
        }

        if (tx) {
          setAmount('')
          setTokenSelected(undefined)

          dispatch(fetchCaloOnchainBallance(account))
          dispatch(fetchFitOnchainBallance(account))
          dispatch(fetchBNBBalance(account))
          dispatch(fetchShoeOnchainBalance(account))
          dispatch(fetchShoeBoxOnchainBalance(account))

          dispatch(
            showToast({
              title: 'Transfer success',
              message:
                'Transaction has submitted. This progress will take (5-10 minutes) to finish.',
            }),
          )
        }
      } catch (error: any) {
        let message = ''

        if (error?.apiStatus === 0) {
          message = error.message
        } else if (error?.message?.includes('rejected transaction')) {
          message = 'User denied transaction signature'
        } else {
          message = 'Something went wrong'
        }

        dispatch(
          showToast({
            title: 'Transfer error',
            message: message || 'Something went wrong',
          }),
        )
      }
      setLoading(false)
      setIsOpenConfirm(false)
    }
  }

  const handleConfirmTransferIndoor = async () => {
    if (account && !!userInfo.caloId && tokenSelected && amount) {
      setLoading(true)
      try {
        let tx

        if (tokenSelected.type === NFT_TYPE.SHOE && tokenSelected.shoe_id) {
          tx = await depositShoeIndoor(
            account,
            userInfo?.caloId,
            tokenSelected.type,
            tokenSelected.shoe_id,
          )
        } else if (
          tokenSelected.type === NFT_TYPE.SHOEBOX &&
          tokenSelected.shoeBoxId
        ) {
          tx = await depositShoeboxIndoor(
            account,
            userInfo?.caloId,
            tokenSelected.type,
            tokenSelected.shoeBoxId,
          )
        } else {
          tx = await depositCaloIFit(
            account,
            userInfo?.caloId,
            tokenSelected.type,
            amount,
          )
        }

        if (tx) {
          setAmount('')
          setTokenSelected(undefined)

          dispatch(fetchCaloOnchainBallance(account))
          dispatch(fetchFitOnchainBallance(account))
          dispatch(fetchBNBBalance(account))
          dispatch(fetchShoeOnchainBalance(account))
          dispatch(fetchShoeBoxOnchainBalance(account))

          dispatch(
            showToast({
              title: 'Transfer success',
              message:
                'Transaction has submitted. This progress will take (5-10 minutes) to finish.',
            }),
          )
        }
      } catch (error: any) {
        let message = ''

        if (error?.apiStatus === 0) {
          message = error.message
        } else if (error?.message?.includes('rejected transaction')) {
          message = 'User denied transaction signature'
        } else {
          message = 'Something went wrong'
        }

        dispatch(
          showToast({
            title: 'Transfer error',
            message: message || 'Something went wrong',
          }),
        )
      }
      setLoading(false)
      setIsOpenConfirm(false)
    }
  }

  const handleApprove = async () => {
    if (tokenSelected) {
      setLoadingApprove(true)
      const contract = getCurrentContract(tokenSelected.type)

      if (contract) {
        try {
          if (tokenSelected.type === NFT_TYPE.SHOE) {
            const tx = await contract.setApprovalForAll(
              getContractAddress('depShoe') || '',
              true,
            )

            await tx.wait()
          } else if (tokenSelected.type === NFT_TYPE.SHOEBOX) {
            const tx = await contract.setApprovalForAll(
              getContractAddress('depShoebox') || '',
              true,
            )

            await tx.wait()
          } else {
            const tx = await contract.approve(
              getContractAddress('token') || '',
              MaxUint256,
            )

            await tx.wait()
          }

          setLoadingApprove(false)
          // await handleConfirmTransfer()
        } catch (error) {
          setLoadingApprove(false)
        }
      }
    }
  }

  const renderAvailableToken = () => {
    if (tokenSelected) {
      if (
        tokenSelected?.type === TOKEN_TYPE.CALO ||
        tokenSelected?.type === TOKEN_TYPE.FIT ||
        tokenSelected?.type === TOKEN_TYPE.IFIT
      ) {
        return (
          <div css={styles.availableBalance}>
            Available: {tokenBalance > 0 ? formatNumber(tokenBalance, 2) : 0}{' '}
            {tokenSelected?.type}
          </div>
        )
      }

      return null
    }
  }

  const tokenList = [
    {
      type: TOKEN_TYPE.CALO,
      image: Images.CaloTokenIcon,
    },
  ]

  if (appSource === SOURCES.INDOOR) {
    tokenList.push({
      type: TOKEN_TYPE.IFIT,
      image: Images.IfitTokenIcon,
    })
  } else {
    tokenList.push({
      type: TOKEN_TYPE.FIT,
      image: Images.FitTokenIcon,
    })
  }

  const formInvalid =
    !tokenSelected || !amount || loading || +amount > tokenBalance

  return (
    <div css={styles.formWrapper}>
      <div css={styles.formControl}>
        <FormControl fullWidth>
          <div css={styles.formLabel}>Asset</div>
          <Global styles={styles.dropdownMenu} />

          <Select
            css={styles.inputWrapper}
            value={JSON.stringify(tokenSelected)}
            onChange={handleTokenChange}
            inputProps={{ 'aria-label': 'Without label' }}
            MenuProps={{
              className: 'calo-dropdown-menu',
            }}
            IconComponent={Images.ArrowDown}
          >
            {tokenList?.length > 0 &&
              tokenList.map((item: ITokenSelect) => {
                return (
                  <MenuItem
                    value={JSON.stringify(item)}
                    css={styles.selectItem}
                    key={item.type}
                  >
                    <img src={item.image} alt={item.type} className='icon' />{' '}
                    {item.type}
                  </MenuItem>
                )
              })}
            {shoeList?.length > 0 &&
              shoeList.map((item: any) => {
                if (item?.status !== NFT_STATUS.COOLDOWN) {
                  return (
                    <MenuItem
                      value={JSON.stringify({ ...item, type: NFT_TYPE.SHOE })}
                      css={styles.selectItem}
                      key={item.shoe_id}
                    >
                      <img
                        src={item.image}
                        alt={item.shoe_id}
                        className='icon'
                      />{' '}
                      {`${item.name} #${item.shoe_id}`}
                    </MenuItem>
                  )
                }

                return null
              })}
            {shoeboxList?.length > 0 &&
              shoeboxList.map((item: any) => {
                return (
                  <MenuItem
                    value={JSON.stringify({ ...item, type: NFT_TYPE.SHOEBOX })}
                    css={styles.selectItem}
                    key={item.shoeBoxId}
                  >
                    <img
                      src={item.image}
                      alt={item.shoebox_id}
                      className='icon'
                    />{' '}
                    {`SHOEBOX ${item.quality} #${item.shoeBoxId}`}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
      </div>
      <div css={styles.formControl}>
        <FormControl fullWidth>
          <div css={styles.formLabel}>Amount</div>

          <OutlinedInput
            value={amount}
            onChange={(e) =>
              setAmount(
                e.target.value
                  .replace(/[^0-9.]/g, '')
                  .replace(/(\..*?)\..*/g, '$1'),
              )
            }
            css={styles.inputWrapper}
            disabled={
              tokenSelected?.type === NFT_TYPE.SHOE ||
              tokenSelected?.type === NFT_TYPE.SHOEBOX
            }
          />
          <div css={styles.validate}>
            {amount && +amount > +tokenBalance && (
              <span>Max: {formatNumber(+tokenBalance, 2)}</span>
            )}
          </div>
          {tokenSelected && renderAvailableToken()}
        </FormControl>
      </div>
      <div css={styles.formControl}>
        {isApproved ? (
          <Button
            onClick={() => setIsOpenConfirm(true)}
            css={[commonClass.appButton, styles.confirmBtn]}
            disabled={formInvalid || !tokenSelected}
          >
            CONFIRM TRANSFER
          </Button>
        ) : (
          <Button
            onClick={handleApprove}
            css={[commonClass.appButton, styles.confirmBtn]}
            disabled={loadingApprove || isApproved || !tokenSelected}
          >
            {!!loadingApprove && <CircularProgress size={18} />}
            APPROVE
          </Button>
        )}
      </div>

      <DepositConfirmModal
        token={tokenSelected}
        amount={amount}
        isLoading={loading}
        isOpen={isOpenConfirm}
        onClose={() => setIsOpenConfirm(false)}
        onConfirm={
          appSource === SOURCES.OUTDOOR
            ? handleConfirmTransferOutdoor
            : handleConfirmTransferIndoor
        }
      />
    </div>
  )
}

export default Deposit
