import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _map from 'lodash/map'
import _ from 'lodash'
import {
  getShoeListApi,
  getShoeBoxListApi,
  openShoeBoxOutdoorApi,
  mintShoeOutdoorApi,
  costMintShoeOutdoorApi,
  boostShoeTimeOutdoorApi,
  upgradeShoeOutdoorApi,
  getShoeDetailApi,
  getShoeBoxDetailApi,
  addShoePointOutdoorApi,
  getRepairShoeCostOutdoorApi,
  repairShoeOutdoorApi,
  getManaListApi,
  getStoneListApi,
  upgradeShoeIndoorApi,
  openShoeBoxIndoorApi,
  mintShoeIndoorApi,
  costMintShoeIndoorApi,
  boostShoeTimeIndoorApi,
  addShoePointIndoorApi,
  getRepairShoeCostIndoorApi,
  repairShoeIndoorApi,
} from 'api/bag'
import { createCallApiAsyncAction } from 'store/middlewares/createAsyncAction'
import { IMana, IShoe, IShoeBox, IStone } from 'types/nft'
import {
  convertStructureShoe,
  convertStuctureMana, convertStuctureShoeBox,
  convertStuctureStone,
} from 'store/middlewares/convert-structure/nft'
export const TYPE = 'BAG'

export const fetchShoeDetail = createCallApiAsyncAction(
  TYPE + '/fetchShoeDetail',
  getShoeDetailApi,
  convertStructureShoe,
)

export const fetchShoeBoxDetail = createCallApiAsyncAction(
  TYPE + '/fetchShoeBoxDetail',
  getShoeBoxDetailApi,
)

export const fetchShoeBagList = createCallApiAsyncAction(
  TYPE + '/fetchShoeBagList',
  getShoeListApi,
  (old) => {
    return {
      data: _map(old.data.data, (v) => convertStructureShoe(v)),
    }
  },
)

export const fetchManaBagList = createCallApiAsyncAction(
  TYPE + '/fetchManaBagList',
  getManaListApi,
  (old) => {
    return {
      data: _map(old.data || _.omit(old, 'apiStatus'), (v) =>
        convertStuctureMana(v),
      ),
    }
  },
)

export const fetchStoneBagList = createCallApiAsyncAction(
  TYPE + '/fetchStoneBagList',
  getStoneListApi,
  (old) => {
    return {
      data: _map(old.data || _.omit(old, 'apiStatus'), (v) =>
        convertStuctureStone(v),
      ),
    }
  },
)

export const fetchShoeBoxBagList = createCallApiAsyncAction(
  TYPE + '/fetchShoeBoxBagList',
  getShoeBoxListApi,
    (old) => {
      return {
        data: _map(old.data.data, (v) => convertStuctureShoeBox(v)),
      }
    },
)

export const fetchBagShoeItem = createCallApiAsyncAction(
  TYPE + '/fetchBagShoeItem',
  getShoeDetailApi,
  convertStructureShoe,
)

export const fetchOpenShoeboxOutdoor = createCallApiAsyncAction(
  TYPE + '/fetchOpenShoebox',
  openShoeBoxOutdoorApi,
)

export const fetchMintShoeOutdoor = createCallApiAsyncAction(
  TYPE + '/fetchMintShoe',
  mintShoeOutdoorApi,
)

export const fetchCostMintOutdoor = createCallApiAsyncAction(
  TYPE + '/fetchCostMint',
  costMintShoeOutdoorApi,
)

export const upgradeShoeOutdoor = createCallApiAsyncAction(
  TYPE + '/upgradeShoe',
  upgradeShoeOutdoorApi,
)

export const boostShoeTimeOutdoor = createCallApiAsyncAction(
  TYPE + '/boostShoeTime',
  boostShoeTimeOutdoorApi,
)

export const addShoePointOutdoor = createCallApiAsyncAction(
  TYPE + '/addShoePoint',
  addShoePointOutdoorApi,
)

export const getRepairShoeCostOutdoor = createCallApiAsyncAction(
  TYPE + '/getRepairShoeCost',
  getRepairShoeCostOutdoorApi,
)

export const repairShoeOutdoor = createCallApiAsyncAction(
  TYPE + '/repairShoe',
  repairShoeOutdoorApi,
)

export const fetchOpenShoeboxIndoor = createCallApiAsyncAction(
  TYPE + '/fetchOpenShoebox',
  openShoeBoxIndoorApi,
)

export const fetchMintShoeIndoor = createCallApiAsyncAction(
  TYPE + '/fetchMintShoe',
  mintShoeIndoorApi,
)

export const fetchCostMintIndoor = createCallApiAsyncAction(
  TYPE + '/fetchCostMint',
  costMintShoeIndoorApi,
)

export const upgradeShoeIndoor = createCallApiAsyncAction(
  TYPE + '/upgradeShoe',
  upgradeShoeIndoorApi,
)

export const boostShoeTimeIndoor = createCallApiAsyncAction(
  TYPE + '/boostShoeTime',
  boostShoeTimeIndoorApi,
)

export const addShoePointIndoor = createCallApiAsyncAction(
  TYPE + '/addShoePoint',
  addShoePointIndoorApi,
)

export const getRepairShoeCostIndoor = createCallApiAsyncAction(
  TYPE + '/getRepairShoeCost',
  getRepairShoeCostIndoorApi,
)

export const repairShoeIndoor = createCallApiAsyncAction(
  TYPE + '/repairShoe',
  repairShoeIndoorApi,
)

interface BagListState {
  shoeData: IShoe[]
  shoeBoxData: IShoeBox[]
  stoneData: IStone[]
  manaData: IMana[]
  shoeDetail: IShoe | null
}

const initialState: BagListState = {
  shoeData: [],
  shoeBoxData: [],
  stoneData: [],
  manaData: [],
  shoeDetail: null,
}

const bagListSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setBagShoeDetail(state, action: PayloadAction<IShoe>) {
      state.shoeDetail = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchManaBagList.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.manaData = response?.data || []
      } else {
        state.manaData = []
      }
    })

    builder.addCase(fetchStoneBagList.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.stoneData = response?.data || []
      } else {
        state.stoneData = []
      }
    })

    builder.addCase(fetchShoeBagList.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.shoeData = response?.data || []
      } else {
        state.shoeData = []
      }
    })

    builder.addCase(fetchShoeBoxBagList.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.shoeBoxData = response?.data || []
      } else {
        state.shoeBoxData = []
      }
    })

    builder.addCase(fetchBagShoeItem.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.shoeDetail = response
      }
    })
  },
})

export const { setBagShoeDetail } = bagListSlice.actions
export default bagListSlice.reducer
