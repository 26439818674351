/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { formatNumber } from 'helpers/formatBalance'
import WalletNavTop from 'components/wallet-nav-top/WalletNavTop'
import { fetchWalletBallance } from 'store/reducers/wallet'
import { useAppDispatch, useAppSelector } from 'store'
import { commonClass } from 'theme'
import Images from 'images'
import Transaction from './Transaction'

import styles from './SpendingWallet.styles'

const SpendingWallet = () => {
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector((state) => state.auth.token)
  const walletBallance = useAppSelector((state) => state.wallet)

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchWalletBallance({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  return (
    <div css={[commonClass.containerSmall, styles.wrapper]}>
      <div css={styles.contentWrapper}>
        <div>
          <WalletNavTop active='SPENDING' />
        </div>
        <br />
        <h3>Spending Account</h3>
        <div css={styles.walletInfo}>
          <div css={styles.walletInfoItem}>
            <div css={styles.tokenIcon}>
              <img src={Images.CaloTokenIcon} alt='calo token' />
              <span>CALO</span>
            </div>

            <span>{formatNumber(walletBallance.calo, 2)}</span>
          </div>

          <div css={styles.walletInfoItem}>
            <div css={styles.tokenIcon}>
              <img src={Images.FitTokenIcon} alt='fit token' />
              <span>FIT</span>
            </div>

            <span>{formatNumber(walletBallance.fit, 2)}</span>
          </div>
          <div css={styles.walletInfoItem}>
            <div css={styles.tokenIcon}>
              <img src={Images.IfitTokenIcon} alt='ifit token' />
              <span>IFIT</span>
            </div>

            <span>{formatNumber(walletBallance.ifit, 2)}</span>
          </div>
        </div>
        <div>
          <Transaction />
        </div>
      </div>
    </div>
  )
}

export default SpendingWallet
