import { css } from '@emotion/react'
import { tabletWidth } from 'theme'

const styles = {
  wrapper: css`
    margin-top: 24px;
    box-sizing: border-box;
    color: white;

    min-height: 100px;
    background: #0c0e1c;
    display: none;

    @media only screen and (min-width: ${tabletWidth}px) {
      display: flex;
    }
    * {
      box-sizing: border-box;
    }
  `,
  content: css`
    width: 100%;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  `,

  contentTop: css`
    display: flex;
  `,
  menu: css`
    flex: 1;
    padding: 29px 0;
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: white;
      margin-bottom: 19px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #bfbfbf;
    }
  `,
  social: css`
    padding: 29px 0;
    flex: 2;
    text-align: right;
  `,
  socials: css`
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    img {
      height: 22px;
      /* width: 22px; */
    }
  `,
  contentBottom: css`
    display: flex;

    border-top: 1px solid #131a2b;

    height: 33px;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;

      font-weight: 600;
      font-size: 12px;
      line-height: 140%;

      color: #bfbfbf;
    }
  `,
}

export default styles
