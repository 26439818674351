/** @jsxImportSource @emotion/react */
import { Modal, Box, Button, CircularProgress } from '@mui/material'
import Images from 'images'
import { commonClass } from 'theme'
import styles from './ConfirmModal.styles'
import { TRANSACTION_ACTIONS } from './TransactionPending'

interface IProps {
  action: string
  isLoading: boolean
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

const ConfirmModal = ({
  action,
  isLoading,
  isOpen,
  onClose,
  onConfirm,
}: IProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box css={styles.modalWrapper}>
        <div className='df jcb'>
          <div css={styles.modalTitle}>Confirm</div>
          <div className='pointer' onClick={onClose}>
            <Images.CloseIcon />
          </div>
        </div>

        <div css={styles.transferToken}>
          {action === TRANSACTION_ACTIONS.CANCEL
            ? 'Are you sure you want to cancel?'
            : 'Are you sure you want to retry?'}
        </div>
        <div css={styles.actions} className='mt-5 mx-auto'>
          <Button
            css={[commonClass.ghostButton, styles.cancelBtn]}
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            onClick={onConfirm}
            css={[commonClass.appButton, styles.confirmBtn]}
            disabled={isLoading}
          >
            {!!isLoading && <CircularProgress size={18} />}
            CONFIRM
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

export default ConfirmModal
