/** @jsxImportSource @emotion/react */
import Images from 'images'
import styles from './Footer.styles'
const Footer = () => {
  return (
    <div css={styles.wrapper}>
      <div css={styles.content}>
        <div css={styles.contentTop}>
          <div css={styles.menu}>
            <h5>CALO</h5>
            <p>Outdoor</p>
            <p>Indoor</p>
          </div>
          <div css={styles.menu}>
            <h5>Promo</h5>
            <p>Invite to earn</p>
            <p>Vip Club</p>
          </div>
          <div css={styles.menu}>
            <h5>Support/Legal</h5>
            <p>Privacy Policy</p>
            <p>Terms & Conditions</p>
          </div>
          <div css={styles.social}>
            <h5>Join us community</h5>
            <div css={styles.socials}>
              <img src={Images.logoTele} alt='' />
              <img src={Images.logoDiscord} alt='' />
              <img src={Images.logoFacebook} alt='' />
            </div>
          </div>
        </div>
        <div css={styles.contentBottom}>
          <p>Metaverse Application Pte, Ltd. </p>
          <p>
            UEN: 202142943M | 1B Trengganu Street Singapore (058455) |
            hi@calo.run
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
