import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { SOURCE_TYPE } from 'types/common'
const TYPE = 'COMMON'

interface IToast {
  message?: string
  title: string
}
interface ICommonState {
  toast: IToast | null
  source: SOURCE_TYPE
}

const initialState: ICommonState = {
  toast: null,
  source: 'OUTDOOR',
}

const commonSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    showToast(state, action: PayloadAction<IToast | null>) {
      state.toast = action.payload
    },
    changeSource(state, action: PayloadAction<SOURCE_TYPE>) {
      state.source = action.payload
    },
  },
})

export const { showToast, changeSource } = commonSlice.actions
export default commonSlice.reducer
