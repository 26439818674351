import { createAsyncThunk } from '@reduxjs/toolkit'
export const createCallApiAsyncAction = <T, R>(
  actionName: string,
  asyncAction: (params: T) => Promise<R>,
  convertStructure?: (
    old: any,
  ) => Omit<R, 'apiStatus' | 'errorStatus' | 'message'>,
) => {
  return createAsyncThunk(actionName, async (params: T, thunkAPI) => {
    // do before api calling
    // eg: thunkAPI.dispatch(actionLoading(true));
    const appSource = (thunkAPI.getState() as any)?.common?.source

    if (params && appSource) {
      // eslint-disable-next-line no-extra-semi
      ;(params as any).targetMarket = appSource
      ;(params as any).game = appSource
    }
    try {
      const response = await asyncAction(params)
      // @ts-ignore

      if (response.apiStatus) {
        if (convertStructure) {
          const newResponse = {
            ...convertStructure(response),
            apiStatus: 1,
            message: '',
            errorStatus: '',
          }

          return {
            response: newResponse,
            request: params,
          }
        }

        return {
          response,
          request: params,
        }
      } else {
        throw response
      }
    } catch (e: any) {
      if (e?.errorStatus === 401) {
        const { removeUserToken } = require('store/reducers/auth')

        thunkAPI.dispatch(removeUserToken())
      }

      return {
        response: e as R,
        request: params,
      }
    }
    // do after api calling finish
    // eg: thunkAPI.dispatch(actionLoading(false));
  })
}
