export interface IWalletBallance {
  fitBalance: number
  caloBalance: number
  ifitBalance: number
}
export interface IWithdrawFee {
  caloAmount: string
  fitAmount: string
  ifitAmount?: string
}

export interface ITransactionHistoryRequest {
  limit?: number
  offset?: number
  filter?: any
  sort?: any
}

export const WITHDRAW_STEPS = {
  INFO: 'INFORMATION',
  OTP: 'OTP',
}
