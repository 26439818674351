export const MARKET_TABS = {
  SHOE: 'SHOE',
  STONE: 'STONE',
  MANA: 'MANA',
}

export const MARKET_SORT_VALUES = {
  PRICE_ASC: 'PRICE_ASC',
  PRICE_DESC: 'PRICE_DESC',
  LATEST: 'LATEST',
}

export const BAG_TABS = {
  SHOE: 'SHOE',
  STONE: 'STONE',
  MANA: 'MANA',
}

export const TRANSFER_DIRECTION = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
}

export const SWAP_DIRECTIONS = {
  IN: 'IN',
  OUT: 'OUT',
}

export const TOKEN_TYPE = {
  CALO: 'CALO',
  FIT: 'FIT',
  IFIT: 'IFIT',
  BNB: 'BNB',
}

export const NFT_TYPE = {
  STONE: 'STONE',
  SHOE: 'SHOE',
  SHOEBOX: 'SHOEBOX',
  MANA: 'MANA',
}

export const NFT_STATUS = {
  ACTIVE: 'ACTIVE',
  LOCKED: 'LOCKED',
  SELLING: 'SELLING',
  LEVELING: 'LEVELING',
  COOLDOWN: 'COOLDOWN',
  CREATED: 'CREATED',
}

export const CUSTOM_EVENTS = {
  REFRESH_CLICKED: 'REFRESH_CLICKED',
}

export const SWAP = {
  SWAP_EXACT_BNB_FOR_CALO: 'swapExactBNBForCalo',
  SWAP_EXACT_CALO_FOR_BNB: 'swapExactCaloForBNB',
  SWAP_EXACT_BNB_FOR_FIT: 'swapExactBNBForFit',
  SWAP_EXACT_FIT_FOR_BNB: 'swapExactFitForBNB',
  SWAP_EXACT_CALO_FOR_FIT: 'swapExactCaloForFit',
  SWAP_EXACT_FIT_FOR_CALO: 'swapExactFitForCalo',
}

export type SOURCE_TYPE = 'INDOOR' | 'OUTDOOR' | 'GLOBAL'

export const SOURCES = {
  INDOOR: 'INDOOR',
  OUTDOOR: 'OUTDOOR',
  GLOBAL: 'GLOBAL',
}
export const PRICE_UNIT = {
  CALO: 'CALO',
  IFIT: 'IFIT',
  FIT: 'FIT',
}

export const CLASSES = {
  INDOOR: [
    {
      label: 'Trainer',
      key: 'Trainer',
    },
    {
      label: 'Slipper',
      key: 'Slipper',
    },
    {
      label: 'Clever',
      key: 'Clever',
    },
    {
      label: 'Pumper',
      key: 'Pumper',
    },
  ],
  OUTDOOR: [
    {
      key: 'Walker',
      label: 'Walker',
    },
    {
      key: 'Jogger',
      label: 'Jogger',
    },
    {
      key: 'Runner',
      label: 'Runner',
    },
    {
      key: 'Trainer',
      label: 'Trainer',
    },
  ],
}
