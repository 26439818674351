/** @jsxImportSource @emotion/react */
import _get from 'lodash/get'
import _toLower from 'lodash/toLower'
import { useState } from 'react'
import { Button } from '@mui/material'
import { css } from '@emotion/react'
import { IShoe } from 'types/nft'
import WrapperBgImg from 'components/wrap-background-img/WrapperBgImg'
import ShoeInfo from 'components/shoe-info/ShoeInfo'
import LifeTimeInfo from 'components/lifetime-info/LifeTimeInfo'
import ProgressBar from 'components/progress-bar/ProgressBar'
import {
  capitalizeFirstLetter,
  getColorStatus,
  getShoeAttribute,
  isMobile,
  toFixedNumber,
} from 'helpers/utils'
import { baseColors, commonClass } from 'theme'
import Images from 'images'
import { IMarketListItem } from 'types/market'
import { NFT_STATUS } from 'types/common'
import styles from './ShoeDetail.styles'
import MintShoes from './MintShoes'
import Sockets from './Sockets'
interface IProps {
  shoeDetail: IShoe
  marketItemDetail?: IMarketListItem
  onBuyClick?: () => void
  onSellMenuClick?: () => void
  onLevelupFooterClick?: () => void
  onRepairFooterClick?: () => void
  onMintFooterClick?: () => void
  onTransferFooterClick?: () => void
  onAddPointClick?: () => void
}

const BASE_STATE = 'BASE'
const POINT_STATE = 'POINT'
const ShoeDetail = ({
  shoeDetail,
  marketItemDetail,
  onBuyClick,
  onSellMenuClick,

  onLevelupFooterClick,
  onRepairFooterClick,
  onMintFooterClick,
  onTransferFooterClick,
  onAddPointClick,
}: IProps) => {
  const isMarketBuyItem = !!marketItemDetail
  const qualityText = capitalizeFirstLetter(shoeDetail?.quality)
  const qualityColor = _get(baseColors, qualityText + 'Color')
  const qualityBg = (Images.qualityBg as any)[
    _toLower(shoeDetail?.quality) || 'common'
  ] as string

  const statusText = capitalizeFirstLetter(shoeDetail?.status)

  const [attrState, setAttrState] = useState(POINT_STATE)

  const { properties, maxAttr } = getShoeAttribute(shoeDetail)

  const renderLifeTimeInfo = () => {
    return <LifeTimeInfo className='mx-auto' shoeDetail={shoeDetail} />
  }

  const renderMintInfo = () => {
    return (
      <>
        <div css={styles.mintText}>Mint {shoeDetail?.mint}/7</div>
        <ProgressBar
          completeColor={qualityColor}
          split
          value={shoeDetail?.mint}
          maxValue={7}
        />
      </>
    )
  }

  const renderActionsMenu = () => {
    if (marketItemDetail?.marketItemId) {
      return null
    }

    const menuActions = [
      {
        icon: Images.shoeMenuIcons.MenuLevelup,
        label: 'Level up',
        onClick: onLevelupFooterClick,
      },
      {
        icon: Images.shoeMenuIcons.MenuRepair,
        label: 'Repair',
        onClick: onRepairFooterClick,
      },
      {
        icon: Images.shoeMenuIcons.MenuMint,
        label: 'Mint',
        onClick: onMintFooterClick,
      },
      {
        icon: Images.shoeMenuIcons.MenuSell,
        label: 'Sell',
        onClick: onSellMenuClick,
      },
      {
        icon: Images.shoeMenuIcons.MenuTransfer,
        label: 'Transfer',
        onClick: onTransferFooterClick,
      },
    ]

    return (
      <div css={styles.actionMenu}>
        {menuActions.map((menuItem) => (
          <div css={styles.menuItem} key={menuItem.label}>
            <Button onClick={menuItem.onClick} className='menuItemIcon'>
              <menuItem.icon />
            </Button>
            <span className='tlabel'>{menuItem.label}</span>
          </div>
        ))}
      </div>
    )
  }

  const renderBuyPrice = () => {
    if (marketItemDetail?.marketItemId) {
      return (
        <div css={[commonClass.hideInMobile, styles.actionsWrapper]}>
          <span>{toFixedNumber(marketItemDetail?.price)} {marketItemDetail?.priceUnit || 'FIT'}</span>

          <Button
            key={'buynow'}
            onClick={onBuyClick}
            css={[commonClass.appButton, styles.confirmBuy]}
          >
            BUY NOW
          </Button>
        </div>
      )
    }

    return null
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.section1}>
        <div css={styles.imgInfoWrapper} className='mx-auto pb-3'>
          <div css={styles.bgWrapper} className='mt-2 mx-auto mb-3'>
            {!isMarketBuyItem &&
            statusText &&
            shoeDetail.status !== NFT_STATUS.ACTIVE ? (
              <div css={styles.tagStatusWrap}>
                <span
                  css={styles.tagStatusText}
                  style={{ color: getColorStatus(shoeDetail.status) }}
                >
                  {statusText}
                </span>
              </div>
            ) : null}
            <WrapperBgImg src={qualityBg}>
              <div css={styles.bgWrapperContent}>
                <Sockets shoeDetail={shoeDetail} />
                <img css={styles.shoeImg} src={shoeDetail?.image} alt='' />

                <div css={styles.shoeId} style={{ color: qualityColor }}>
                  #{shoeDetail?.shoeId}
                </div>
              </div>
            </WrapperBgImg>
          </div>

          <div className='mx-auto mt-3' css={styles.shoeInfoWrapper}>
            <ShoeInfo
              className={`mx-auto ${
                isMobile() ? 'w-100 mw-100' : 'w-90 mw-90'
              }`}
              level={shoeDetail?.level}
              shoeClass={shoeDetail?.class}
              durable={shoeDetail?.durable}
              color={qualityColor}
            />
          </div>

          <div
            css={[styles.mintWrapper, commonClass.hideInDesktop]}
            className='mb-3 mx-auto mt-3'
          >
            {renderLifeTimeInfo()}
          </div>

          <div
            css={[styles.mintWrapper, commonClass.hideInDesktop]}
            className='mt-3 mx-auto'
          >
            {renderMintInfo()}
          </div>
        </div>
        <div
          css={[
            commonClass.hideInMobile,
            styles.actionsWrapper,
            {
              background: 'transparent !important',
            },
          ]}
        >
          {renderActionsMenu()}
        </div>
      </div>
      <div css={styles.section2}>
        <div css={[styles.desktopInfo, commonClass.hideInMobile]}>
          <div css={styles.mintWrapper} className='mx-auto'>
            {renderLifeTimeInfo()}
          </div>

          <div css={styles.mintWrapper} className='mt-3 mx-auto'>
            {renderMintInfo()}
          </div>
        </div>

        <div css={styles.shoeAttrWrapper}>
          <div className='dfac jcb'>
            <span css={styles.attrTitle}>Attribute</span>

            <div className='dfac'>
              <button
                key={'base'}
                onClick={() =>
                  attrState === BASE_STATE
                    ? setAttrState(POINT_STATE)
                    : setAttrState(BASE_STATE)
                }
                className={attrState === BASE_STATE ? 'inactive' : ''}
                css={[commonClass.appButton, styles.attributeButton]}
              >
                Base
              </button>
              {!isMarketBuyItem && !!shoeDetail.point && !!onAddPointClick && (
                <button
                  onClick={() => onAddPointClick()}
                  css={[commonClass.appButton, styles.attributeButton]}
                  className='ml-2 px-2 outline'
                >
                  Point
                  <span
                    className='ml-1'
                    style={{ color: baseColors.primaryColor }}
                  >
                    +{shoeDetail.point}
                  </span>
                </button>
              )}
            </div>
          </div>

          <div>
            {properties.map((attr) => (
              <div key={attr.label} className='dfac' css={styles.attributeWrap}>
                <div
                  css={[styles.attributeIcon, css(attr.iconStyle)]}
                  className='dfc'
                >
                  <span
                    css={[
                      styles.attributeTextIcon,
                      css({ color: attr.iconStyle.color }),
                    ]}
                  >
                    {attr.label[0]}
                  </span>
                </div>
                <div css={styles.progressWrapper}>
                  <span css={styles.progressLabel}>{attr.label}</span>
                  <ProgressBar
                    value={attr.value}
                    value2={attrState === BASE_STATE ? 0 : attr.value2}
                    value3={attrState === BASE_STATE ? 0 : attr.value3}
                    maxValue={Math.max(attr.total, maxAttr)}
                  />
                </div>
                <span css={styles.attributeValueLabel}>
                  {attrState === BASE_STATE
                    ? toFixedNumber(attr.value)
                    : toFixedNumber(attr.total)}
                </span>
              </div>
            ))}
          </div>
        </div>

        {renderBuyPrice()}

        <MintShoes
          style={{ marginTop: 16 }}
          mints={[shoeDetail.leftShoe, shoeDetail.rightShoe]}
          title='Minted From'
          wrapStyle={{
            backgroundColor: baseColors.bgTabBar,
            backgroundImage: 'initial',
            borderRadius: 8,
          }}
        />

        <MintShoes
          style={{ marginTop: 16 }}
          mints={shoeDetail.mintTo}
          title='Mints'
          wrapStyle={{
            backgroundColor: baseColors.bgTabBar,
            backgroundImage: 'initial',
            borderRadius: 8,
          }}
        />
      </div>

      <div css={[commonClass.hideInDesktop, styles.actionsWrapper]}>
        {renderActionsMenu()}
      </div>
    </div>
  )
}

export default ShoeDetail
