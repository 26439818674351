import { css } from '@emotion/react'
import { isMobile } from 'helpers/utils'
import { tabletWidth } from 'theme'
const socketSize = isMobile() ? 32 : 48
const stoneSize = isMobile() ? 28 : 40
const styles = {
  stoneImgWrap: css`
    height: ${socketSize}px;
    width: ${socketSize}px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  `,
  stoneImg: css`
    height: ${stoneSize}px;
    width: ${stoneSize}px;
  `,
  socket: css`
    height: ${socketSize}px;
    width: ${socketSize}px;
    position: absolute;
    svg {
      height: ${socketSize}px;
      width: ${socketSize}px;
    }
    .lock-img {
      height: ${socketSize}px;
      width: ${socketSize}px;
    }
  `,
  socket1: css`
    top: 32px;
    left: 20px;
  `,
  socket2: css`
    top: 32px;
    right: 20px;
  `,
  socket3: css`
    bottom: 50px;
    left: 20px;

    @media only screen and (min-width: ${tabletWidth}px) {
      bottom: 120px;
    }
  `,
  socket4: css`
    bottom: 50px;
    right: 20px;

    @media only screen and (min-width: ${tabletWidth}px) {
      bottom: 120px;
    }
  `,
}

export default styles
