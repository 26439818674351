/** @jsxImportSource @emotion/react */
import {
  Modal,
  Box,
  Button,
  CircularProgress,
  TextField,
  InputAdornment,
  Typography,
  Switch,
} from '@mui/material'
import _toLower from 'lodash/toLower'
import _get from 'lodash/get'
import { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import Images from 'images'
import WrapperBgImg from 'components/wrap-background-img/WrapperBgImg'
import { baseColors, commonClass } from 'theme'
import {
  capitalizeFirstLetter,
  formatInputNumber,
  getManaImage,
  getStoneImage,
  getStoneTypeColor,
} from 'helpers/utils'
import ShoeInfo from 'components/shoe-info/ShoeInfo'
import { useAppDispatch, useAppSelector } from 'store'
import { showToast } from 'store/reducers/common'
import { sellMarketItem } from 'store/reducers/marketList'
import { NFT_TYPE, PRICE_UNIT, SOURCES } from 'types/common'
import { IMana, IShoe, IShoeBox, IStone } from 'types/nft'
import styles from './SellModal.styles'
interface IProps {
  isOpen: boolean
  handleClose: () => void
  itemDetail: IShoe | IShoeBox | IStone | IMana | null
  onSellSuccess: () => void
  type: string
}

const SellModal = ({
  isOpen,
  handleClose,
  itemDetail,
  onSellSuccess,
  type,
}: IProps) => {
  const dispatch = useAppDispatch()
  const appSource = useAppSelector((state) => state.common.source) || 'OUTDOOR'

  const [loading, setLoading] = useState(false)
  const [price, setPrice] = useState(0)
  const [step, setStep] = useState(1)
  const [isGlobalMarket, setGlobalMarket] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setPrice(0)
      setStep(1)
      setLoading(false)
    }
  }, [isOpen])

  const getPriceUnit = () => {
    if (isGlobalMarket) {
      return PRICE_UNIT.CALO
    }

    if (appSource === SOURCES.INDOOR) {
      return PRICE_UNIT.IFIT
    }

    if (appSource === SOURCES.OUTDOOR) {
      return PRICE_UNIT.FIT
    }

    return PRICE_UNIT.CALO
  }

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalMarket(event.target.checked)
  }

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(formatInputNumber(event.target.value))
  }

  const handleConfirm = () => {
    if (type) {
      setLoading(true)
      const saveObj = {
        price,
        itemId: 0,
        type,
        global: isGlobalMarket,
      }

      if (type === NFT_TYPE.SHOE) {
        saveObj.itemId = (itemDetail as IShoe).shoeId
      }
      if (type === NFT_TYPE.SHOEBOX) {
        saveObj.itemId = (itemDetail as IShoeBox).shoeBoxId
      }

      if (type === NFT_TYPE.STONE) {
        saveObj.itemId = (itemDetail as IStone).stoneId
      }

      if (type === NFT_TYPE.MANA) {
        saveObj.itemId = (itemDetail as IMana).manaId
      }

      if (saveObj.itemId && saveObj.price) {
        dispatch(sellMarketItem(saveObj))
          .then(unwrapResult)
          .then(({ response }) => {
            if (response.apiStatus) {
              onSellSuccess()
            } else {
              dispatch(
                showToast({
                  title: 'Error',
                  message: response.message,
                }),
              )
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }

  const renderMarketTarget = (itemDetail: IShoe) => {
    if (type === NFT_TYPE.SHOEBOX) {
      return null
    }
    if (type === NFT_TYPE.SHOE && itemDetail?.class !== 'TRAINER') {
      return null
    }

    return (
      <div css={styles.chooseMarket}>
        <div css={styles.chooseMarketLabel}>CHOOSE MARKET</div>
        <Switch
          checked={isGlobalMarket}
          onChange={handleChangeSwitch}
          inputProps={{ 'aria-label': 'controlled' }}
          size='medium'
        />
      </div>
    )
  }

  const renderDetailItem = () => {
    if (type === NFT_TYPE.SHOE) {
      return renderShoeDetail(itemDetail as IShoe)
    }

    if (type === NFT_TYPE.SHOEBOX) {
      return renderShoeBoxDetail(itemDetail as IShoeBox)
    }

    if (type === NFT_TYPE.STONE) {
      return renderStoneDetail(itemDetail as IStone)
    }

    if (type === NFT_TYPE.MANA) {
      return renderManaDetail(itemDetail as IMana)
    }

    return null
  }

  const renderShoeDetail = (shoeDetail: IShoe) => {
    const qualityText = capitalizeFirstLetter(shoeDetail?.quality)
    const qualityColor = _get(baseColors, qualityText + 'Color')
    const qualityBg = (Images.qualityBg as any)[
      _toLower(shoeDetail?.quality) || 'common'
    ] as string

    return (
      <>
        <div css={styles.bgWrapper}>
          <WrapperBgImg src={qualityBg}>
            <div css={styles.bgWrapperContent}>
              <img css={styles.shoeImg} src={shoeDetail?.image} alt='' />

              <div
                className='mb-2'
                css={styles.shoeId}
                style={{ color: qualityColor }}
              >
                #{shoeDetail?.shoeId}
              </div>

              <div className='mb-3 mx-auto' css={styles.shoeInfoWrapper}>
                <ShoeInfo
                  className='mx-auto'
                  level={shoeDetail?.level}
                  shoeClass={shoeDetail?.class}
                  durable={shoeDetail?.durable}
                  color={qualityColor}
                />
              </div>
            </div>
          </WrapperBgImg>
        </div>
      </>
    )
  }

  const renderShoeBoxDetail = (shoeboxDetail: IShoeBox) => {
    const qualityText = capitalizeFirstLetter(shoeboxDetail?.quality)
    const qualityColor = _get(baseColors, qualityText + 'Color')
    const qualityBg = (Images.qualityBg as any)[
      _toLower(shoeboxDetail?.quality) || 'common'
    ] as string

    const imgShoeboxSrc =
      appSource === SOURCES.OUTDOOR
        ? Images.shoebox.outdoor
        : Images.shoebox.indoor

    const shoeboxImg = (imgShoeboxSrc as any)[
      _toLower(shoeboxDetail?.quality) || 'common'
    ] as string

    return (
      <>
        <div css={styles.bgWrapper}>
          <WrapperBgImg src={qualityBg}>
            <div css={styles.bgWrapperContent}>
              <img
                css={styles.shoeImg}
                src={shoeboxImg}
                alt=''
                className='m-0'
              />

              <div
                className='mb-2'
                css={styles.shoeId}
                style={{ color: qualityColor }}
              >
                #{shoeboxDetail?.shoeBoxId}
              </div>
            </div>
          </WrapperBgImg>
        </div>
      </>
    )
  }

  const renderStoneDetail = (itemDetail: IStone) => {
    const stoneImg = getStoneImage(itemDetail.type, itemDetail.level)
    const typeText = capitalizeFirstLetter(itemDetail.type)
    const colorType = getStoneTypeColor(typeText)

    return (
      <>
        <div css={styles.bgWrapper}>
          <div css={styles.bgWrapperContent}>
            <img css={styles.shoeImg} src={stoneImg} alt='' className='m-0' />

            <div
              className='mb-2'
              css={styles.shoeId}
              style={{ color: colorType }}
            >
              #{itemDetail?.stoneId}
            </div>

            <Typography css={styles.shoeId} color={baseColors.neutralColor1}>
              +{itemDetail.attribute} | +{itemDetail.awakeningEffect}% | Level{' '}
              {itemDetail.level}
            </Typography>
          </div>
        </div>
      </>
    )
  }

  const renderManaDetail = (itemDetail: IMana) => {
    const qualityText = capitalizeFirstLetter(itemDetail?.quality)
    const qualityColor = _get(baseColors, qualityText + 'Color')

    const manaImg = getManaImage(itemDetail.quality, itemDetail.size)

    return (
      <>
        <div css={styles.bgWrapper}>
          <div css={styles.bgWrapperContent}>
            <img css={styles.shoeImg} src={manaImg} alt='' className='m-0' />

            <div
              className='mb-2'
              css={styles.shoeId}
              style={{ color: qualityColor }}
            >
              #{itemDetail?.manaId}
            </div>
            <Typography css={styles.shoeId} color={baseColors.neutralColor1}>
              {capitalizeFirstLetter(itemDetail.size)} | +{itemDetail.point}{' '}
              point
            </Typography>
          </div>
        </div>
      </>
    )
  }

  const renderPriceInput = () => {
    return (
      <>
        <div css={styles.priceInputWrapper}>
          <div css={styles.priceLabel}>Selling price</div>
          <TextField
            css={styles.priceInput}
            type='text'
            value={price}
            onChange={handleChangePrice}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>{getPriceUnit()}</InputAdornment>
              ),
            }}
          />
        </div>
      </>
    )
  }

  const renderSellStep1 = () => {
    return (
      <>
        {renderDetailItem()}
        {renderMarketTarget(itemDetail as IShoe)}
        {renderPriceInput()}

        <div css={styles.actions} className='mt-5 mx-auto'>
          <Button
            css={[commonClass.ghostButton, styles.cancelBtn]}
            onClick={handleClose}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => setStep(2)}
            disabled={+price <= 0}
            css={[commonClass.appButton, styles.confirmBtn]}
          >
            CONFIRM
          </Button>
        </div>
      </>
    )
  }

  const renderSellStep2 = () => {
    return (
      <>
        <div css={styles.confirmInfo}>
          <div css={styles.confirmInfoItem}>
            <span>List Price</span>
            <span>
              {price} {getPriceUnit()}
            </span>
          </div>

          <div css={styles.confirmInfoItem}>
            <span>Creator Royalties</span>
            <span>4%</span>
          </div>

          <div css={styles.confirmInfoItem}>
            <span>Transaction Fee</span>
            <span>2%</span>
          </div>

          <div css={styles.confirmInfoItem}>
            <span>Listing/Cancel</span>
            <span>Free</span>
          </div>
        </div>
        <div css={styles.actions} className='mt-5 mx-auto'>
          <Button
            css={[commonClass.ghostButton, styles.cancelBtn]}
            onClick={() => setStep(1)}
          >
            CANCEL
          </Button>
          <Button
            onClick={handleConfirm}
            css={[commonClass.appButton, styles.confirmBtn]}
            disabled={loading}
          >
            {!!loading && <CircularProgress size={18} />}
            CONFIRM
          </Button>
        </div>
      </>
    )
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box css={styles.wrapper}>
        <>
          <div className='df jcb'>
            <div css={styles.modalTitle}>
              {step === 1 && 'Sell'}
              {step === 2 && 'Confirm to sell'}
            </div>
            <div className='pointer' onClick={handleClose}>
              <Images.CloseIcon />
            </div>
          </div>
          {step === 1 && renderSellStep1()}
          {step === 2 && renderSellStep2()}
        </>
      </Box>
    </Modal>
  )
}

export default SellModal
