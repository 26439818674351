/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import Images from 'images'
import { getClassObj } from 'helpers/utils'
import styles from './ShoeInfo.styles'
interface IProps {
  level: any
  shoeClass: any
  durable: any
  color: any
  className?: string
}
const ShoeInfo = ({ level, shoeClass, durable, color, className }: IProps) => {
  const { label: classLabel, value: classValue } = getClassObj(shoeClass)
  const ClassIcon = Images.classIcon[classValue]

  return (
    <div css={styles.wrapper} className={className}>
      <div
        css={[
          styles.level,
          css`
            color: ${color};
          `,
        ]}
        className='dfc'
      >
        <span>Lv {level}</span>
      </div>

      <div
        css={[
          styles.class,
          css`
            color: ${color};
            svg path {
              fill: ${color};
            }
          `,
        ]}
        className='dfc'
      >
        <ClassIcon /> <span>{classLabel}</span>
      </div>

      <div css={[styles.durableWrapper]}>
        <div
          css={[
            styles.durableBack,
            css`
              background-color: ${color};
            `,
          ]}
        >
          <span>{durable}/100</span>
        </div>
        <div
          css={[styles.durableFront]}
          style={{
            clipPath: `inset(0 0 0 ${durable}%)`,
          }}
        >
          <span>{durable}/100</span>
        </div>
      </div>
    </div>
  )
}

export default ShoeInfo
