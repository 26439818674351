import { IShoe, IShoeBox } from 'types/nft'
import { Request } from 'helpers/request'
import { IWalletBallance, ITransactionHistoryRequest } from 'types/wallet'

export const getWalletBalanceApi = () => {
  return Request.call<IWalletBallance>({
    url: '/wallet.get_balance',
    method: 'POST',
  })
}

export const getTransactionHistoryApi = (payload?: ITransactionHistoryRequest) => {
  return Request.call<{ data: any }>({
    url: '/wallet.transactions',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const cancelTransactionApi = (payload?: {
  txId: string
}) => {
  return Request.call<{ data: any }>({
    url: '/wallet.cancel_transactions',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getTransactionApi = (payload?: {
  txId: string
}) => {
  return Request.call<{ data: any }>({
    url: '/wallet.get_transactions',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getWithdrawFeeApi = (data: {
  tokenType?: string
  amount?: string
}) => {
  // return Request.call<IWithdrawFee>({
  //   url: '/wallet.get_withdraw_fee',
  //   method: 'POST',
  //   data,
  // })
  return Request.call<any>({
    url: '/public.get_fee_app',
    method: 'POST',
    data,
  })
}

export const getWithdrawOTPApi = (data: {}) => {
  return Request.call<any>({
    url: '/wallet.send_otp',
    method: 'POST',
    data,
  })
}

export const depositToken = (data: {
  tokenType: string
  amount: string
  address: string
  nftId?: number
}) => {
  return Request.call<{ txId: string }>({
    url: '/wallet.deposit',
    method: 'POST',
    data,
  })
}

export const withdrawToken = (data: {
  otp: string,
  tokenType: string
  amount: string | number
  address: string
  nftId?: number
  isWithdrawV2?: boolean
}) => {
  return Request.call<{
    amount: any
    nonce: any
    receiver: string
    signature: string
    userId: string
    nftId?: number
  }>({
    url: '/wallet.withdraw',
    method: 'POST',
    data,
  })
}

export const getShoeboxDetail = (shoeboxId: any) => {
  return Request.call<IShoeBox>({
    url: `/shoebox/${shoeboxId}`,
    method: 'GET',
  })
}

export const getShoeDetail = (shoeId: any) => {
  return Request.call<IShoe>({
    url: `/shoe/${shoeId}`,
    method: 'GET',
  })
}
