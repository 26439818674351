/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Box } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useNavigate } from 'react-router-dom'
import { formatNumber } from 'helpers/formatBalance'
import { showToast } from 'store/reducers/common'
import { fetchWalletBallance } from 'store/reducers/wallet'
import {
  fetchBNBBalance,
  fetchCaloOnchainBallance,
  fetchFitOnchainBallance,
  fetchShoeOnchainBalance,
  fetchShoeBoxOnchainBalance,
  fetchIFitOnchainBalance,
  fetchIndoorShoeOnchainBalance,
  fetchIndoorShoeBoxOnchainBalance,
} from 'store/reducers/walletOnchain'

import { useAppDispatch, useAppSelector } from 'store'
import Images from 'images'
import ConnectWalletButton from 'components/connect-wallet-button/ConnectWalletButton'
import WalletNavTop from 'components/wallet-nav-top/WalletNavTop'
import { commonClass } from 'theme'
import { truncateAddress } from 'helpers/text'
import { ROUTE_PATHS } from 'routes'
import { SOURCES } from 'types/common'
import WalletMenu from './WalletMenu'
import styles from './OnChainWallet.styles'

const OnChainWallet = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const accessToken = useAppSelector((state) => state.auth.token)
  const walletOnchain = useAppSelector((state) => state.walletOnchain)
  const appSource = useAppSelector((state) => state.common.source) || 'OUTDOOR'

  const navigate = useNavigate()

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchWalletBallance({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  useEffect(() => {
    if (account) {
      dispatch(fetchCaloOnchainBallance(account))
      dispatch(fetchBNBBalance(account))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    if (account && appSource === SOURCES.OUTDOOR) {
      dispatch(fetchFitOnchainBallance(account))
      dispatch(fetchShoeOnchainBalance(account))
      dispatch(fetchShoeBoxOnchainBalance(account))
    }
    if (account && appSource === SOURCES.INDOOR) {
      dispatch(fetchIFitOnchainBalance(account))
      dispatch(fetchIndoorShoeOnchainBalance(account))
      dispatch(fetchIndoorShoeBoxOnchainBalance(account))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, appSource])

  return (
    <div css={[commonClass.containerSmall, styles.wrapper]}>
      <div css={styles.contentWrapper}>
        <div>
          <WalletNavTop active='ONCHAIN' />
        </div>
        <Box css={styles.accountWrapper}>
          <div>
            {account ? (
              <div>
                <div css={styles.bnbBalanceTop}>
                  {formatNumber(walletOnchain?.bnb || 0)} BNB
                </div>
                <CopyToClipboard
                  text={account}
                  onCopy={() =>
                    dispatch(
                      showToast({
                        title: 'Copied',
                      }),
                    )
                  }
                >
                  <div css={styles.walletAddress}>
                    {truncateAddress(account, 15, '...')}
                  </div>
                </CopyToClipboard>
              </div>
            ) : (
              <div css={styles.connectWalletWrapper}>
                <ConnectWalletButton width='full' />
              </div>
            )}
          </div>
        </Box>

        {account && (
          <div>
            <WalletMenu />
            <h3>Wallet Account</h3>
            <div css={styles.walletInfo}>
              <div css={styles.walletInfoItem}>
                <div css={styles.tokenIcon}>
                  <img src={Images.CaloTokenIcon} alt='calo token' />
                  <span>Calo</span>
                </div>

                <span>{formatNumber(walletOnchain?.calo || 0)}</span>
              </div>

              <div css={styles.walletInfoItem}>
                <div css={styles.tokenIcon}>
                  <img src={Images.FitTokenIcon} alt='fit token' />
                  <span>FIT</span>
                </div>

                <span>{formatNumber(walletOnchain?.fit || 0)}</span>
              </div>
              <div css={styles.walletInfoItem}>
                <div css={styles.tokenIcon}>
                  <img src={Images.IfitTokenIcon} alt='ifit token' />
                  <span>IFIT</span>
                </div>

                <span>{formatNumber(walletOnchain?.ifit || 0)}</span>
              </div>
            </div>
            <div css={styles.walletInfo}>
              <div
                css={styles.walletInfoItem}
                onClick={() => navigate(ROUTE_PATHS.WALLET_SHOE)}
              >
                <div css={styles.tokenIcon}>
                  <img src={Images.ShoeIcon} alt='Sneakers' />
                  <span>Sneakers</span>
                </div>

                <span>{formatNumber(walletOnchain?.shoe?.length || 0)}</span>
              </div>

              <div
                css={styles.walletInfoItem}
                onClick={() => navigate(ROUTE_PATHS.WALLET_SHOEBOX)}
              >
                <div css={styles.tokenIcon}>
                  <img src={Images.ShoeboxIcon} alt='Shoeboxes' />
                  <span>Shoeboxes</span>
                </div>

                <span>{formatNumber(walletOnchain?.shoebox?.length || 0)}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default OnChainWallet
