import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import storage from 'redux-persist/lib/storage'
import auth from './reducers/auth'
import wallet from './reducers/wallet'
import walletOnchain from './reducers/walletOnchain'
import common from './reducers/common'
import marketList from './reducers/marketList'
import bag from './reducers/bag'
import transaction from './reducers/transaction'

const rootReducer = combineReducers({
  auth,
  common,
  marketList,
  bag,
  wallet,
  walletOnchain,
  transaction,
})
const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: ['auth', 'common'],
    transforms: [
      encryptTransform({
        secretKey: process.env.REACT_APP_STORAGE_KEY as string,
      }),
    ],
  },
  rootReducer,
)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
