/** @jsxImportSource @emotion/react */
import { Modal, Box, Button, CircularProgress } from '@mui/material'
import { ethers } from 'ethers'
import { truncateAddress } from 'helpers/text'
import Images from 'images'
import { commonClass } from 'theme'
import { NFT_TYPE } from 'types/common'
import styles from './ConfirmModal.styles'

interface IProps {
  token: any
  amount: string | undefined
  address: string
  isLoading: boolean
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

const TransferConfirmModal = ({
  token,
  amount,
  address,
  isLoading,
  isOpen,
  onClose,
  onConfirm,
}: IProps) => {
  const renderTotalToken = () => {
    if (token?.type === NFT_TYPE.SHOE) {
      return `Shoe #${token?.shoe_id}`
    } else if (token?.type === NFT_TYPE.SHOEBOX) {
      return `Shoebox #${token?.shoeBoxId}`
    }

    return `${amount} ${token?.type}`
  }

  const isAddress = ethers.utils.isAddress(address)

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box css={styles.modalWrapper}>
        <div className='df jcb'>
          <div css={styles.modalTitle}>Confirm Transfer</div>
          <div className='pointer' onClick={onClose}>
            <Images.CloseIcon />
          </div>
        </div>
        <div css={styles.transferToken}>
          <div css={styles.transferItem}>
            <div css={styles.transferItemLabel}>You will transfer</div>
            <div css={styles.transferItemValue}>{renderTotalToken()}</div>
          </div>
        </div>
        <div css={styles.transferToken}>
          <div css={styles.transferItem}>
            <div css={styles.transferItemLabel}>Send address</div>
            <div css={styles.transferItemValue}>
              {truncateAddress(address, 14, '...')}
            </div>
          </div>
        </div>
        <div css={styles.actions} className='mt-5 mx-auto'>
          <Button
            css={[commonClass.ghostButton, styles.cancelBtn]}
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            onClick={onConfirm}
            css={[commonClass.appButton, styles.confirmBtn]}
            disabled={isLoading || !isAddress}
          >
            {!!isLoading && <CircularProgress size={18} />}
            CONFIRM
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

export default TransferConfirmModal
