/** @jsxImportSource @emotion/react */
import { ThemeProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'

import createCache from '@emotion/cache'
import { BrowserRouter } from 'react-router-dom'

import { useEffect } from 'react'
import Header from 'components/header/Header'
import { globalTheme, leftMenuWidth } from 'theme'
import Router from 'routes'
import GlobalAlert from 'components/global-alert/GlobalAlert'
import useEagerConnect from 'hooks/useEagerConnect'
import { useAppDispatch } from 'store'
import { fetchFirebaseConfig } from 'store/reducers/auth'
import { isMobile } from 'helpers/utils'
// import Images from 'images'

const cache = createCache({
  key: 'css',
  prepend: true,
})

const App = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchFirebaseConfig({}))
  }, [])
  useEagerConnect()

  // return (
  //   <div>
  //     <img style={{ height: '100vh', width: '100%' }} src={Images.maintenance} />
  //   </div>
  // )

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={globalTheme}>
        <div style={{ background: '#101222' }}>
          <BrowserRouter>
            <Header />
            <div
              style={
                !isMobile()
                  ? {
                      marginLeft: leftMenuWidth,
                      minHeight: 'calc(100vh - 54px)',
                    }
                  : {
                      marginBottom: 60,
                    }
              }
            >
              <Router />
            </div>
          </BrowserRouter>
          <GlobalAlert />
        </div>
      </ThemeProvider>
    </CacheProvider>
  )
}

export default App
