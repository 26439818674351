import { css } from '@emotion/react'
import Images from 'images'
import { baseColors, tabletWidth } from 'theme'

const styles = {
  headerStyles: css`
    height: 54px;
    z-index: 2;
    position: relative;

    @media only screen and (min-width: ${tabletWidth}px) {
      box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.25);
    }
  `,
  avatar: css`
    margin-left: 16px;
    cursor: pointer;
    height: 36px;
    width: 36px;
    img {
      height: 36px;
      width: 36px;
    }
  `,
  inner: css`
    max-width: 923px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 2px 12px;
  `,

  mobile: css`
    position: absolute;
    display: block;
    width: 100%;
    background: #101222;
    @media only screen and (min-width: ${tabletWidth}px) {
      display: none;
    }
  `,
  mobileProfile: css`
    padding: 16px;
  `,
  mobileProfileItem: css`
    background: #15172d;
    border-radius: 2px;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    gap: 20px;
    img {
      height: 48px;
      width: 48px;
    }
    & > div {
      flex: 1;
    }
    h5 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.0125em;
      color: #ffffff;
      margin: 0;
      margin-bottom: 8px;
    }
    p {
      margin: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #00bbf9;
      margin-bottom: 0px;
    }
  `,
  mobileWallet: css`
    display: flex;
    gap: 4px;
  `,
  mobileWalletInfo: css`
    display: flex;
    padding: 0 16px;
    background: #15172d;
    height: 40px;
    flex: 1;
    align-items: center;
    border-radius: 2px;

    gap: 11px;
    img {
    }
  `,
  mobileWalletCoin: css`
    img {
      height: 20px;
      width: 20px;
    }
  `,
  mobileWalletLink: css`
    padding: 0 16px;
    background: #15172d;
    border-radius: 2px;
    flex: 1;
    align-items: center;
    display: flex;
    gap: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #ffffff;
    img {
      height: 16px;
    }
  `,

  mobileLogout: css`
    height: 36px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-top: 1px solid #121529;
    gap: 12px;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    color: #738dc3;
  `,
  platformMobile: css`
    width: 100%;
    display: flex;
    height: 54px;
    background: #1a1c2c;

    a {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 20px;
      }
    }
  `,
  desktop: css`
    display: none;
    @media only screen and (min-width: ${tabletWidth}px) {
      display: flex;
      flex: 1;
    }
  `,
  platform: css`
    align-items: center;
    height: 54px;
    gap: 8px;
    a {
      img {
        height: 20px;
      }
    }
    display: flex;
  `,
  wrapper: css`
    background: #1a1c2c;
    width: 100%;
    margin-left: 0;
    @media only screen and (min-width: ${tabletWidth}px) {
      width: calc(100% - 236px);
      margin-left: 236px;
    }
  `,
  logo: css`
    gap: 15px;
    flex: 1;
    display: flex;
    align-items: center;
    @media only screen and (min-width: ${tabletWidth}px) {
      display: none;
    }
  `,
  profile: css`
    flex: 1;
    display: none;
    align-items: center;
    justify-content: flex-end;
    @media only screen and (min-width: ${tabletWidth}px) {
      display: flex;
    }
  `,
  loginText: css`
    color: white;
    margin: 0;
    padding: 6px 24px;
    cursor: pointer;
  `,

  walletInfo: css`
    border-radius: 8px;
    padding: 4px;
    display: flex;
  `,
  walletInfoItem: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 0;
    margin-left: 8px;
    margin-right: 8px;
    gap: 8px;
    cursor: pointer;
  `,
  tokenIcon: css`
    display: flex;
    align-items: center;
    img {
      height: 20px;
      width: 20px;
    }
  `,

  walletLink: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #ffffff;

    height: 54px;
    width: 130px;

    background-image: url(${Images.walletBg});
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    cursor: pointer;
    img {
      height: 24px;
      width: 24px;
    }
  `,

  footerStyle: css`
    height: 60px;
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100vw;

    @media only screen and (min-width: ${tabletWidth}px) {
      display: none;
    }
  `,
  footerMenuWrapper: css`
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #21243e;
  `,
  menuItem: css`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      height: 24px;
      width: 24px;
    }
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #807f7f;
    }
  `,
  menuItemActive: css`
    border-color: ${baseColors.primaryColor3};
    span {
      color: #fff;
    }
  `,
}

export default styles
