/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
} from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import Images from 'images'
import { commonClass } from 'theme'
import { useAppDispatch, useAppSelector } from 'store'
import { WITHDRAW_STEPS } from 'types/wallet'
import { getWithdrawOTP } from 'store/reducers/wallet'
import styles from './ConfirmModal.styles'
import { IWithdrawProps } from './WithdrawConfirmModal'

const SEND_CODE_TIME = 60

const VerifyWithdrawOTP = ({
  otp,
  isLoading,
  onClose,
  onConfirm,
  setOtp,
  setStep,
}: IWithdrawProps) => {
  const dispatch = useAppDispatch()
  const userInfo = useAppSelector((state) => state.auth.userInfo)
  const [loadingCode, setLoadingCode] = useState(false)
  const [time, setTime] = useState(SEND_CODE_TIME)

  let interval: string | number | NodeJS.Timer | undefined

  const handleGetOtp = () => {
    setTime(SEND_CODE_TIME - 1)
    setLoadingCode(true)
    dispatch(getWithdrawOTP({}))
      .then(unwrapResult)
      .then(({ response }: any) => {
        if (response.apiStatus) {
          interval = setInterval(() => {
            setTime((a) => a - 1)
          }, 1000)
        } else {
          setOtp('')
        }
        setLoadingCode(false)
      })
      .catch((error) => {
        setLoadingCode(false)
      })

    return () => {
      clearInterval(interval)
    }
  }

  return (
    <Box css={styles.modalWrapper}>
      <div className='df jcb'>
        <div css={styles.modalTitle}>Withdraw code</div>
        <div className='pointer' onClick={onClose}>
          <Images.CloseIcon />
        </div>
      </div>

      <div css={styles.otpSendTo}>
        <div>Code will send to</div>
        <div>{userInfo?.email}</div>
      </div>
      <div css={styles.formControl}>
        <FormControl fullWidth>
          <OutlinedInput
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            css={styles.inputWrapper}
          />
          {time > 0 && time < SEND_CODE_TIME ? (
            <div css={[styles.sendOtpBtn, styles.timeCountdown]}>{time}</div>
          ) : loadingCode ? (
            <CircularProgress size={16} css={styles.sendOtpBtn} />
          ) : (
            <Button
              variant='text'
              css={styles.sendOtpBtn}
              onClick={handleGetOtp}
            >
              SEND OTP
            </Button>
          )}
        </FormControl>
      </div>
      <div css={styles.actions} className='mx-auto'>
        <Button
          css={[commonClass.ghostButton, styles.cancelBtn]}
          onClick={() => {
            setStep(WITHDRAW_STEPS.INFO)
            onClose()
          }}
        >
          CANCEL
        </Button>
        <Button
          onClick={onConfirm}
          css={[commonClass.appButton, styles.confirmBtn]}
          disabled={isLoading}
        >
          {!!isLoading && <CircularProgress size={18} />}
          CONFIRM
        </Button>
      </div>
    </Box>
  )
}

export default VerifyWithdrawOTP
