/** @jsxImportSource @emotion/react */
import { Slider } from '@mui/material'
import { useEffect, useState } from 'react'
import Images from 'images'
import { baseColors } from 'theme'
import { CLASSES, MARKET_TABS, NFT_TYPE, SOURCES, SOURCE_TYPE } from 'types/common'
import styles from './FilterMenu.styles'

export interface FilterMenuSelectProps {
  title: string
  selectList: Array<any>
  value?: string
  onChange: Function
}

export interface FilterMenuSlideProps {
  title: string
  value: [number, number]
  min: number
  max: number
  step: number
  onChange: Function
}

export interface IFilterData {
  type?: string
  class?: string
  quality?: string
  stoneType?: string
  size?: string
  level?: [number, number]
  mint?: [number, number]
  lifeTime?: [number, number]
  stoneQuality?: [number, number]
  targetMarket?: SOURCE_TYPE
  global?: boolean
}

export interface FilterMenuProps {
  onSave: Function
  onChange: Function
  value?: IFilterData
  type?: string
  sourceState?: string
}

const FilterMenuSelect = ({
  selectList,
  value,
  onChange,
  title,
}: FilterMenuSelectProps) => {
  const [isShow, setShow] = useState(false)

  const number = selectList.filter((a) => a.key == value).length

  return (
    <div css={styles.item}>
      <div css={styles.label} onClick={() => setShow(!isShow)}>
        {title}
        {number ? <span css={styles.number}>{number}</span> : ''}
        <div style={{ flex: 1 }} />
        {isShow ? <Images.ArrowDown /> : <Images.ArrowRight />}
      </div>
      {isShow && (
        <div css={styles.selectList}>
          {selectList.map((item) => (
            <div
              key={item.key}
              css={styles.selectItem}
              onClick={() => onChange(item.key)}
            >
              {item.key === value ? (
                <img src={Images.Checked} alt='' />
              ) : (
                <img src={Images.Check} alt='' />
              )}

              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const FilterMenuSlide = ({
  value,
  onChange,
  title,
  min,
  max,
  step,
}: FilterMenuSlideProps) => {
  const [v, setV] = useState<[number, number]>(value)
  const [isShow, setShow] = useState(false)

  return (
    <div css={styles.item}>
      <div css={styles.label} onClick={() => setShow(!isShow)}>
        {title}
        <div style={{ flex: 1 }} />
        <div css={styles.number2}>
          {v[0]}-{v[1]}
        </div>
        {isShow ? <Images.ArrowDown /> : <Images.ArrowRight />}
      </div>
      {isShow && (
        <div css={styles.slider}>
          <div css={styles.minMax}>
            <p>{min}</p>
            <p>{max}</p>
          </div>
          <Slider
            value={v}
            onChange={(e: any) => {
              if (e.target.value) {
                setV(e.target.value || [0, 0])
              }
            }}
            onChangeCommitted={() => {
              onChange(v)
            }}
            valueLabelDisplay='auto'
            min={min}
            max={max}
            step={step}
            size='small'
          />
        </div>
      )}
    </div>
  )
}

const FilterMenu = ({ onSave, value, onChange, type, sourceState }: FilterMenuProps) => {
  const [data, setData] = useState<IFilterData>({ ...value })
  // const count = Object.values(data).filter((a) => a).length

  const getClassSelectOption = () => {
    if (sourceState === SOURCES.INDOOR) {
      return CLASSES.INDOOR
    }

    return CLASSES.OUTDOOR
  }

  useEffect(() => {
    onChange(data)
  }, [data])

  const renderFilterShoe = () => {
    return (
      <div css={styles.content}>
        <FilterMenuSelect
          title='PLACES'
          selectList={[
            {
              key: 'GLOBAL',
              label: 'Global',
            },
          ]}
          value={data.targetMarket}
          onChange={(e: string) => {
            setData({
              ...data,
              targetMarket:
                data.targetMarket === 'GLOBAL' ? undefined : 'GLOBAL',
            })
          }}
        />

        <FilterMenuSelect
          title='TYPES'
          selectList={[
            {
              key: NFT_TYPE.SHOE,
              label: 'Sneakers',
            },
            {
              key: NFT_TYPE.SHOEBOX,
              label: 'Shoeboxes',
            },
          ]}
          value={data.type}
          onChange={(e: string) => {
            setData({ type: data.type === e ? '' : e })
          }}
        />
        {(data.type === NFT_TYPE.SHOE) && (
          <FilterMenuSelect
            title='CLASSES'
            selectList={getClassSelectOption()}
            value={data.class}
            onChange={(e: string) => {
              setData({ ...data, class: data.class === e ? '' : e })
            }}
          />
        )}
        {data.type && (
          <FilterMenuSelect
            title='QUALITY'
            selectList={[
              {
                key: 'Common',
                label: 'Common',
                color: baseColors.CommonColor,
              },
              {
                key: 'Uncommon',
                label: 'Uncommon',
                color: baseColors.UncommonColor,
              },
              {
                key: 'Rare',
                label: 'Rare',
                color: baseColors.RareColor,
              },
              {
                key: 'Epic',
                label: 'Epic',
                color: baseColors.EpicColor,
              },
              {
                key: 'Legendary',
                label: 'Legendary',
                color: baseColors.LegendaryColor,
              },
            ]}
            value={data.quality}
            onChange={(e: string) => {
              setData({ ...data, quality: data.quality === e ? '' : e })
            }}
          />
        )}

        {data.type === NFT_TYPE.SHOE && (
          <FilterMenuSlide
            title='LEVEL'
            min={0}
            max={30}
            step={1}
            value={data.level || [0, 30]}
            onChange={(e: [number, number]) => {
              setData({ ...data, level: e })
            }}
          />
        )}
        {data.type === NFT_TYPE.SHOE && (
          <FilterMenuSlide
            title='SHOE MINT'
            min={0}
            step={1}
            max={7}
            value={data.mint || [0, 7]}
            onChange={(e: [number, number]) => {
              setData({ ...data, mint: e })
            }}
          />
        )}
      </div>
    )
  }

  const renderFilterStone = () => {
    return (
      <div css={styles.content}>
        <FilterMenuSelect
          title='PLACES'
          selectList={[
            {
              key: 'GLOBAL',
              label: 'Global',
            },
          ]}
          value={data.targetMarket}
          onChange={(e: string) => {
            setData({
              ...data,
              targetMarket:
                data.targetMarket === 'GLOBAL' ? undefined : 'GLOBAL',
            })
          }}
        />
        <FilterMenuSelect
          title='Type'
          selectList={[
            {
              key: 'Performance',
              label: 'Performance',
              color: baseColors.performanceColor,
            },
            {
              key: 'Recovery',
              label: 'Recovery',
              color: baseColors.recoveryColor,
            },
            {
              key: 'Speed',
              label: 'Speed',
              color: baseColors.speedColor,
            },
            {
              key: 'Luck',
              label: 'Luck',
              color: baseColors.luckColor,
            },
          ]}
          value={data.stoneType}
          onChange={(e: string) => {
            setData({ ...data, stoneType: data.stoneType === e ? '' : e })
          }}
        />

        {/* <FilterMenuSlide
          title='Level'
          min={1}
          max={9}
          step={1}
          value={data.level || [1, 9]}
          onChange={(e: [number, number]) => {
            setData({ ...data, level: e })
          }}
        /> */}
      </div>
    )
  }

  const renderFilterMana = () => {
    return (
      <div css={styles.content}>
        <FilterMenuSelect
          title='PLACES'
          selectList={[
            {
              key: 'GLOBAL',
              label: 'Global',
            },
          ]}
          value={data.targetMarket}
          onChange={(e: string) => {
            setData({
              ...data,
              targetMarket:
                data.targetMarket === 'GLOBAL' ? undefined : 'GLOBAL',
            })
          }}
        />
        <FilterMenuSelect
          title='Size'
          selectList={[
            {
              key: 'SMALL',
              label: 'SMALL',
            },
            {
              key: 'MEDIUM',
              label: 'MEDIUM',
            },
            {
              key: 'LARGE',
              label: 'LARGE',
            },
          ]}
          value={data.size}
          onChange={(e: string) => {
            setData({ size: data.size === e ? '' : e })
          }}
        />
        {/* <FilterMenuSlide
          title='Level'
          min={1}
          max={9}
          step={1}
          value={data.level || [1, 9]}
          onChange={(e: [number, number]) => {
            setData({ ...data, level: e })
          }}
        /> */}
        <FilterMenuSelect
            title='QUALITY'
            selectList={[
              {
                key: 'Common',
                label: 'Common',
                color: baseColors.CommonColor,
              },
              {
                key: 'Uncommon',
                label: 'Uncommon',
                color: baseColors.UncommonColor,
              },
              {
                key: 'Rare',
                label: 'Rare',
                color: baseColors.RareColor,
              },
              {
                key: 'Epic',
                label: 'Epic',
                color: baseColors.EpicColor,
              },
              {
                key: 'Legendary',
                label: 'Legendary',
                color: baseColors.LegendaryColor,
              },
            ]}
            value={data.quality}
            onChange={(e: string) => {
              setData({ ...data, quality: data.quality === e ? '' : e })
            }}
          />
      </div>
    )
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.header}>
        <p css={styles.headerTitle} onClick={() => setData({})}>
          Clear all
        </p>
        <p css={styles.save} onClick={() => onSave(data)}>
          Save
        </p>
      </div>

      {type === MARKET_TABS.SHOE && renderFilterShoe()}
      {type === MARKET_TABS.STONE && renderFilterStone()}
      {type === MARKET_TABS.MANA && renderFilterMana()}

      {/* <div css={styles.actions}>
        <Button
          css={[commonClass.ghostButton, styles.actionClear]}
          onClick={() => setData({})}
        >
          CLEAR FILTER
        </Button>
        <Button
          css={[commonClass.appButton, styles.actionSave]}
          onClick={() => onSave(data)}
        >
          CONFIRM
        </Button>
      </div> */}
    </div>
  )
}

export default FilterMenu
