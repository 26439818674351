/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { baseColors } from 'theme'
import styles from './ProgressBar.styles'

interface IProps {
  split?: any
  maxValue?: any
  value?: any
  value2?: any
  value3?: any
  completeColor?: any
  height?: any
}
const ProgressBar = ({
  split,
  maxValue,
  value,
  value2,
  value3,
  completeColor,
  height = 8,
}: IProps) => {
  const completedBar1Style = [
    styles.completedBar,
    css({
      height: height,
      ...(completeColor && { backgroundColor: completeColor }),
    }),
  ]
  const completedBar2Style = [
    styles.completedBar,
    css({
      height: height,
      backgroundColor: baseColors.progressBarColor2,
    }),
  ]

  const completedBar3Style = [
    styles.completedBar,
    css({
      height: height,
      backgroundColor: baseColors.progressBarColor3,
    }),
  ]

  const renderSplitBar = () => {
    const bars = []
    const barWidth = 95 / maxValue + '%'

    for (let i = 1; i <= maxValue; i++) {
      bars.push(
        <div
          key={i}
          css={[styles.bar, css({ width: barWidth, height: height })]}
        >
          <div
            css={[completedBar1Style, css({ width: i <= value ? '100%' : 0 })]}
          />
        </div>,
      )
    }

    return <div css={styles.splitBarWrapper}>{bars}</div>
  }

  const renderProgressBar = () => {
    const completeBars: { style: any[] }[] = []

    ;[
      {
        value: value,
        style: completedBar1Style,
      },
      {
        value: value2,
        style: completedBar2Style,
      },
      {
        value: value3,
        style: completedBar3Style,
      },
    ].forEach((v, i) => {
      if (v.value && Number(v.value)) {
        completeBars.push({
          style: [
            v.style,
            css({
              width: Math.round((v.value / maxValue) * 100) + '%',
            }),
          ],
        })
      }
    })

    if (!completeBars.length) {
      return <div css={[styles.bar, css({ height: height })]} />
    }

    return (
      <div css={[styles.bar, css({ height: height })]}>
        {completeBars.map((c, idx) => (
          <div key={idx} css={c.style} />
        ))}
      </div>
    )
  }

  return split ? renderSplitBar() : renderProgressBar()
}

export default ProgressBar
