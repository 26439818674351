/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Modal, Box, Button, OutlinedInput } from '@mui/material'
import Images from 'images'
import { commonClass } from 'theme'
import styles from './SlippageModal.styles'

interface IProps {
  slippage: any
  isOpen: boolean
  onConfirm: (value: any) => void
  setShowModal: (value: boolean) => void
}

const defaultList = [0.01, 0.02, 0.05, 0.1]
const MIN_SLIPPAGE = '1'
const MAX_SLIPPAGE = '100'

const SlippageModal = ({
  slippage,
  isOpen,
  onConfirm,
  setShowModal,
}: IProps) => {
  const [inputValue, setInputValue] = useState<any>(0)

  useEffect(() => {
    if (slippage) {
      setInputValue(Number(slippage) * 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slippage])

  const handleValueChange = (e: any) => {
    const newSlippage = e.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')

    if (newSlippage) {
      let slippageValue = ''

      slippageValue =
        newSlippage.indexOf('.') >= 0
          ? newSlippage.substr(0, newSlippage.indexOf('.')) +
            newSlippage.substr(newSlippage.indexOf('.'), 2)
          : newSlippage
      if (parseFloat(slippageValue) < 0) {
        slippageValue = MIN_SLIPPAGE
      } else if (parseFloat(slippageValue) > 100) {
        slippageValue = MAX_SLIPPAGE
      }
      setInputValue(slippageValue)
    } else {
      setInputValue('')
    }
  }

  const handleSave = () => {
    if (inputValue) {
      const value = inputValue ? Number(inputValue) / 100 : 0

      onConfirm(value)
    } else {
      onConfirm(0)
    }
  }

  return (
    <Modal open={isOpen} onClose={() => setShowModal(false)}>
      <Box css={styles.modalWrapper}>
        <div className='df jcb'>
          <div css={styles.modalTitle}>Settings</div>
          <div className='pointer' onClick={() => setShowModal(false)}>
            <Images.CloseIcon />
          </div>
        </div>
        <div>
          <div>Slippage Tolerance</div>
        </div>
        <div css={styles.slippageBox}>
          {defaultList.map((value) => {
            return (
              <div
                css={[
                  styles.slippageItem,
                  value * 100 === parseFloat(inputValue)
                    ? styles.slippageItemActive
                    : '',
                ]}
                key={value}
              >
                <div
                  onClick={() => {
                    setInputValue((value * 100).toString())
                  }}
                >
                  <span>{value * 100}%</span>
                </div>
              </div>
            )
          })}
        </div>
        <div css={styles.inputWrapper}>
          <OutlinedInput
            value={inputValue.toString()}
            onChange={handleValueChange}
            css={styles.input}
          />
          <div css={styles.inputSuffix}>
            <span>%</span>
          </div>
        </div>
        <div css={styles.actions} className='mt-5 mx-auto'>
          <Button
            css={[commonClass.ghostButton, styles.cancelBtn]}
            onClick={() => setShowModal(false)}
          >
            CANCEL
          </Button>
          <Button
            onClick={handleSave}
            css={[commonClass.appButton, styles.confirmBtn]}
            disabled={!inputValue}
          >
            CONFIRM
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

export default SlippageModal
