/* eslint-disable max-params */
import { ethers } from 'ethers'
import { depositToken, withdrawToken } from 'api/wallet'
import { TOKEN_TYPE } from 'types/common'
import {
  getDepositWithdrawTokenContract,
  getDepositWithdrawShoeContract,
  getDepositWithdrawShoeboxContract,
  getDepositWithdrawIndoorShoeContract,
  getDepositWithdrawIndoorShoeboxContract,
  getDepositWithdrawIndoorTokenContract,
} from 'utils/contractHelpers'

// OUTDOOR
// eslint-disable-next-line max-params
export const depositCaloFit = async (
  walletAddress: string,
  caloId: string,
  tokenType: string,
  amount: string,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const amountWei = ethers.utils.parseEther(amount)

  const res = await depositToken({
    tokenType,
    amount,
    address: walletAddress,
  })

  if (res?.txId) {
    try {
      const depositContract = getDepositWithdrawTokenContract(signer)
      // deposit CALO

      if (tokenType === TOKEN_TYPE.CALO) {
        const tx = await depositContract.depositCalo(
          caloId,
          amountWei,
          res?.txId,
        )

        const receipt = await tx.wait()

        return receipt
      }
      // deposit FIT
      if (tokenType === TOKEN_TYPE.FIT) {
        const tx = await depositContract.depositFitToContract(
          caloId,
          amountWei,
          res?.txId,
        )
        const receipt = await tx.wait()

        return receipt
      }
    } catch (error) {
      throw error
    }
  } else {
    throw res
  }
}
// eslint-disable-next-line max-params
export const depositShoe = async (
  walletAddress: string,
  caloId: string,
  tokenType: string,
  shoeId: number,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const res = await depositToken({
    tokenType,
    amount: '1',
    address: walletAddress,
    nftId: shoeId,
  })

  if (res?.txId) {
    try {
      const depositContract = getDepositWithdrawShoeContract(signer)

      const tx = await depositContract.depositNFT(caloId, shoeId, res?.txId)

      const receipt = await tx.wait()

      return receipt
    } catch (error) {
      throw error
    }
  } else {
    throw res
  }
}
// eslint-disable-next-line max-params
export const depositShoebox = async (
  walletAddress: string,
  caloId: string,
  tokenType: string,
  shoeboxId: number,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const res = await depositToken({
    tokenType,
    amount: '1',
    address: walletAddress,
    nftId: shoeboxId,
  })

  if (res?.txId) {
    try {
      const depositContract = getDepositWithdrawShoeboxContract(signer)

      const tx = await depositContract.depositNFT(caloId, shoeboxId, res?.txId)

      const receipt = await tx.wait()

      return receipt
    } catch (error) {
      throw error
    }
  } else {
    throw res
  }
}

export const withdrawCaloFit = async (
  walletAddress: string,
  tokenType: string,
  amount: string,
  otp: string,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const res = await withdrawToken({
    otp,
    tokenType,
    amount,
    address: walletAddress,
  })

  if (res?.signature) {
    const { amount, nonce, receiver, signature, userId } = res

    try {
      const withdrawContract = getDepositWithdrawTokenContract(signer)
      // withdraw CALO

      if (tokenType === TOKEN_TYPE.CALO) {
        const tx = await withdrawContract.withdrawCalo(
          receiver,
          userId,
          ethers.utils.parseEther(amount).toString(),
          nonce,
          signature,
        )

        const receipt = await tx.wait()

        return receipt
      }

      // withdraw FIT
      if (tokenType === TOKEN_TYPE.FIT) {
        const tx = await withdrawContract.withdrawFitFromContract(
          receiver,
          userId,
          ethers.utils.parseEther(amount).toString(),
          nonce,
          signature,
        )
        const receipt = await tx.wait()

        return receipt
      }
    } catch (error) {
      throw error
    }
  } else {
    throw res
  }
}

export const withdrawShoe = async (
  walletAddress: string,
  tokenType: string,
  shoeId: number,
  otp: string,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const res = await withdrawToken({
    otp,
    tokenType,
    amount: 1,
    address: walletAddress,
    nftId: shoeId,
  })

  if (res?.signature) {
    const { nonce, receiver, signature, userId, nftId } = res

    try {
      const withdrawContract = getDepositWithdrawShoeContract(signer)
      const tx = await withdrawContract.withdrawNFT(
        receiver,
        userId,
        nftId,
        nonce,
        signature,
      )

      const receipt = await tx.wait()

      return receipt
    } catch (error) {
      throw error
    }
  } else {
    throw res
  }
}

export const withdrawShoebox = async (
  walletAddress: string,
  tokenType: string,
  shoeboxId: number,
  otp: string,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const res = await withdrawToken({
    otp,
    tokenType,
    amount: 1,
    address: walletAddress,
    nftId: shoeboxId,
  })

  if (res?.signature) {
    const { nonce, receiver, signature, userId, nftId } = res

    try {
      const withdrawContract = getDepositWithdrawShoeboxContract(signer)
      const tx = await withdrawContract.withdrawNFT(
        receiver,
        userId,
        nftId,
        nonce,
        signature,
      )

      const receipt = await tx.wait()

      return receipt
    } catch (error) {
      throw error
    }
  } else {
    throw res
  }
}

// INDOOR
export const depositCaloIFit = async (
  walletAddress: string,
  caloId: string,
  tokenType: string,
  amount: string,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const amountWei = ethers.utils.parseEther(amount)

  const res = await depositToken({
    tokenType,
    amount,
    address: walletAddress,
  })

  if (res?.txId) {
    try {
      const depositContract = getDepositWithdrawIndoorTokenContract(signer)
      // deposit CALO

      if (tokenType === TOKEN_TYPE.CALO) {
        const tx = await depositContract.depositCalo(
          caloId,
          amountWei,
          res?.txId,
        )

        const receipt = await tx.wait()

        return receipt
      }
      // deposit IFIT
      if (tokenType === TOKEN_TYPE.IFIT) {
        const tx = await depositContract.depositIFitToContract(
          caloId,
          amountWei,
          res?.txId,
        )
        const receipt = await tx.wait()

        return receipt
      }
    } catch (error) {
      throw error
    }
  } else {
    throw res
  }
}
// eslint-disable-next-line max-params
export const depositShoeIndoor = async (
  walletAddress: string,
  caloId: string,
  tokenType: string,
  shoeId: number,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const res = await depositToken({
    tokenType,
    amount: '1',
    address: walletAddress,
    nftId: shoeId,
  })

  if (res?.txId) {
    try {
      const depositContract = getDepositWithdrawIndoorShoeContract(signer)

      const tx = await depositContract.depositNFT(caloId, shoeId, res?.txId)

      const receipt = await tx.wait()

      return receipt
    } catch (error) {
      throw error
    }
  } else {
    throw res
  }
}
// eslint-disable-next-line max-params
export const depositShoeboxIndoor = async (
  walletAddress: string,
  caloId: string,
  tokenType: string,
  shoeboxId: number,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const res = await depositToken({
    tokenType,
    amount: '1',
    address: walletAddress,
    nftId: shoeboxId,
  })

  if (res?.txId) {
    try {
      const depositContract = getDepositWithdrawIndoorShoeboxContract(signer)

      const tx = await depositContract.depositNFT(caloId, shoeboxId, res?.txId)

      const receipt = await tx.wait()

      return receipt
    } catch (error) {
      throw error
    }
  } else {
    throw res
  }
}

export const withdrawCaloIFit = async (
  walletAddress: string,
  tokenType: string,
  amount: string,
  otp: string,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const res = await withdrawToken({
    otp,
    tokenType,
    amount,
    address: walletAddress,
  })

  if (res?.signature) {
    const { amount, nonce, receiver, signature, userId } = res

    try {
      const withdrawContract = getDepositWithdrawIndoorTokenContract(signer)

      // withdraw CALO
      if (tokenType === TOKEN_TYPE.CALO) {
        const tx = await withdrawContract.withdrawCalo(
          receiver,
          userId,
          ethers.utils.parseEther(amount).toString(),
          nonce,
          signature,
        )

        const receipt = await tx.wait()

        return receipt
      }

      // withdraw IFIT
      if (tokenType === TOKEN_TYPE.IFIT) {
        const tx = await withdrawContract.withdrawIFitFromContract(
          receiver,
          userId,
          ethers.utils.parseEther(amount).toString(),
          nonce,
          signature,
        )
        const receipt = await tx.wait()

        return receipt
      }
    } catch (error) {
      throw error
    }
  } else {
    throw res
  }
}

export const withdrawShoeIndoor = async (
  walletAddress: string,
  tokenType: string,
  shoeId: number,
  otp: string,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const res = await withdrawToken({
    otp,
    tokenType,
    amount: 1,
    address: walletAddress,
    nftId: shoeId,
  })

  if (res?.signature) {
    const { nonce, receiver, signature, userId, nftId } = res

    try {
      const withdrawContract = getDepositWithdrawIndoorTokenContract(signer)
      const tx = await withdrawContract.withdrawNFT(
        receiver,
        userId,
        nftId,
        nonce,
        signature,
      )

      const receipt = await tx.wait()

      return receipt
    } catch (error) {
      throw error
    }
  } else {
    throw res
  }
}

export const withdrawShoeboxIndoor = async (
  walletAddress: string,
  tokenType: string,
  shoeboxId: number,
  otp: string,
) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const res = await withdrawToken({
    otp,
    tokenType,
    amount: 1,
    address: walletAddress,
    nftId: shoeboxId,
  })

  if (res?.signature) {
    const { nonce, receiver, signature, userId, nftId } = res

    try {
      const withdrawContract = getDepositWithdrawShoeboxContract(signer)
      const tx = await withdrawContract.withdrawNFT(
        receiver,
        userId,
        nftId,
        nonce,
        signature,
      )

      const receipt = await tx.wait()

      return receipt
    } catch (error) {
      throw error
    }
  } else {
    throw res
  }
}
