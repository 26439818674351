/** @jsxImportSource @emotion/react */
import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import Images from 'images'
import styles from './Breadcrumb.styles'
interface IBreadcrumItem {
  label: string
  url?: string
}
interface IProps {
  value: IBreadcrumItem[]
}
const Breadcrumb = ({ value }: IProps) => {
  return (
    <Breadcrumbs
      css={styles.wrapper}
      separator={<Images.ArrowRight />}
      aria-label='breadcrumb'
    >
      {value.map((item, idx) => {
        if (idx === value.length - 1) {
          return (
            <span css={styles.lastItem} key={item.label}>
              {item.label}
            </span>
          )
        }
        if (item.url)
          return (
            <Link
              to={item.url}
              key={item.label}
              color='inherit'
              css={styles.item}
            >
              {item.label}
            </Link>
          )
      })}
    </Breadcrumbs>
  )
}

export default Breadcrumb
