import { Request } from 'helpers/request'
import {
  IShoeBoxListResponse,
  IShoeListRequest,
  IShoeListResponse,
  IStoneListRequest,
} from 'types/bag'
import { ICostMint, IMana, IShoe, IShoeBox, IStone } from 'types/nft'

export const getShoeListApi = (payload: IShoeListRequest) => {
  return Request.call<IShoeListResponse>({
    url: '/user.nft.list',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getShoeDetailApi = (payload: { shoeId: number }) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.detail',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getShoeBoxDetailApi = (payload: { shoeBoxId: number }) => {
  return Request.call<IShoeBox>({
    url: '/nft.shoebox.detail',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getShoeBoxListApi = (payload: IShoeListRequest) => {
  return Request.call<IShoeBoxListResponse>({
    url: '/user.nft.list',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getManaListApi = (payload: IStoneListRequest) => {
  return Request.call<{
    data: IMana[]
  }>({
    url: '/mana.list',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getStoneListApi = (payload: IStoneListRequest) => {
  return Request.call<{
    data: IStone[]
  }>({
    url: '/nft.stone.list',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

// OUTDOOR
export const openShoeBoxOutdoorApi = (payload: { shoeBoxId: number }) => {
  // return Promise.resolve({
  //   openedShoeId: 280545712,
  //   apiStatus: 1,
  //   message: '',
  // } as any)

  return Request.call<IShoeBox>({
    url: '/nft.shoebox.open',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_OUTDOOR_BASE_API,
  })
}

export const upgradeShoeOutdoorApi = (payload: { shoeId: number }) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.upgrade',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_OUTDOOR_BASE_API,
  })
}

export const boostShoeTimeOutdoorApi = (payload: { shoeId: number }) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.boost_time',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_OUTDOOR_BASE_API,
  })
}

export const mintShoeOutdoorApi = (payload: {
  leftShoeId: number
  rightShoeId: number
}) => {
  // return Promise.resolve(shoeboxMock() as any)

  return Request.call<IShoeBox>({
    url: '/nft.shoe.mint',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_OUTDOOR_BASE_API,
  })
}

export const costMintShoeOutdoorApi = (payload: {
  leftShoeId: number
  rightShoeId: number
}) => {
  return Request.call<ICostMint>({
    url: '/nft.shoe.mint.cost',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_OUTDOOR_BASE_API,
  })
}

export const addShoePointOutdoorApi = (payload: {
  shoeId: number
  performance?: number
  luck?: number
  speed?: number
  recovery?: number
}) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.add_point',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_OUTDOOR_BASE_API,
  })
}

export const repairShoeOutdoorApi = (payload: {
  shoeId: number
  durable: number
}) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.repair',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_OUTDOOR_BASE_API,
  })
}

export const getRepairShoeCostOutdoorApi = (payload: { shoeId: number }) => {
  return Request.call<{ fitCost: number; ifitCost?: number }>({
    url: '/nft.shoe.repair.cost',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_OUTDOOR_BASE_API,
  })
}

// INDOOR
export const openShoeBoxIndoorApi = (payload: { shoeBoxId: number }) => {
  return Request.call<IShoeBox>({
    url: '/nft.shoebox.open',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_INDOOR_BASE_API,
  })
}

export const upgradeShoeIndoorApi = (payload: { shoeId: number }) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.upgrade',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_INDOOR_BASE_API,
  })
}

export const boostShoeTimeIndoorApi = (payload: { shoeId: number }) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.boost_time',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_INDOOR_BASE_API,
  })
}

export const mintShoeIndoorApi = (payload: {
  leftShoeId: number
  rightShoeId: number
}) => {
  // return Promise.resolve(shoeboxMock() as any)

  return Request.call<IShoeBox>({
    url: '/nft.shoe.mint',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_INDOOR_BASE_API,
  })
}

export const costMintShoeIndoorApi = (payload: {
  leftShoeId: number
  rightShoeId: number
}) => {
  return Request.call<ICostMint>({
    url: '/nft.shoe.mint.cost',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_INDOOR_BASE_API,
  })
}

export const addShoePointIndoorApi = (payload: {
  shoeId: number
  performance?: number
  luck?: number
  speed?: number
  recovery?: number
}) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.add_point',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_INDOOR_BASE_API,
  })
}

export const repairShoeIndoorApi = (payload: {
  shoeId: number
  durable: number
}) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.repair',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_INDOOR_BASE_API,
  })
}

export const getRepairShoeCostIndoorApi = (payload: { shoeId: number }) => {
  return Request.call<{ ifitCost: number; fitCost?: number }>({
    url: '/nft.shoe.repair.cost',
    method: 'POST',
    data: {
      ...payload,
    },
    serverBaseUrl: process.env.REACT_APP_INDOOR_BASE_API,
  })
}
