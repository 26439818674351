/** @jsxImportSource @emotion/react */
import { Drawer } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import _startsWith from 'lodash/startsWith'
// import useAuth from 'hooks/useAuth'
import { useAppDispatch, useAppSelector } from 'store'
import { fetchMeInfo } from 'store/reducers/auth'
import { ROUTE_PATHS } from 'routes'
import { fetchWalletBallance } from 'store/reducers/wallet'
import { isMobile } from 'helpers/utils'
import Images from 'images'
import styles from './UserMenu.styles'

interface IProps {
  isOpen: boolean
  handleClose: () => void
}

const UserMenu = ({ isOpen, handleClose }: IProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  // const { logout } = useAuth()
  const location = useLocation()

  const accessToken = useAppSelector((state) => state.auth.token)
  // const walletBallance = useAppSelector((state) => state.wallet)
  // const handleLogout = () => {
  //   logout()
  //   dispatch(removeUserToken())
  // }

  const handleNavigate = (pathName: string) => {
    navigate(pathName)

    handleClose()
  }

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchMeInfo({}))
      dispatch(fetchWalletBallance({}))
    }
  }, [accessToken])

  const isMatchPath = (path: string) => {
    return _startsWith(location.pathname, path)
  }

  const menuNavs = [
    {
      icon: Images.MarketIcon,
      activeIcon: Images.MarketActiveIcon,
      label: 'Market Place',
      url: ROUTE_PATHS.MARKET,
    },
    {
      icon: Images.BagIcon,
      activeIcon: Images.BagActiveIcon,
      label: 'Bag',
      url: ROUTE_PATHS.BAG,
    },
    // {
    //   icon: Images.BagIcon,
    //   activeIcon: Images.BagActiveIcon,
    //   label: 'Wallet',
    //   url: ROUTE_PATHS.WALLET_SPENDING,
    // },
  ]

  const renderMenuContent = () => {
    return (
      <div css={styles.menuWrapper}>
        <div css={styles.logo}>
          <Link to={ROUTE_PATHS.MARKET}>
            <img height={40} src={Images.GlobalMarketLogo} alt='' />
          </Link>
        </div>
        <div css={styles.wrapAds}>
          <div css={styles.label}>My VIP Perks</div>
          <div css={[styles.ads, styles.ads1]}>
            <div css={[styles.adsInner, styles.adsInner1]}>
              <img height={45} src={Images.luckyPin} alt='' />
              LUCKY SPIN
            </div>
          </div>
          <div css={[styles.ads, styles.ads2]}>
            <div css={[styles.adsInner, styles.adsInner2]}>
              <img height={45} src={Images.vipClub} alt='' />
              VIP CLUB
            </div>
          </div>
          <div css={[styles.ads, styles.ads3]}>
            <div css={[styles.adsInner, styles.adsInner3]}>
              <img height={45} src={Images.giftInvite} alt='' />
              INVITE TO EARN
            </div>
          </div>
        </div>

        {/* <div css={styles.walletInfo}>
          <div css={styles.walletInfoItem}>
            <div css={styles.tokenIcon}>
              <img src={Images.CaloTokenIcon} alt='calo token' />
              <span>Calo</span>
            </div>

            <span>{toFixedNumber(walletBallance.calo)}</span>
          </div>

          <div css={styles.walletInfoItem}>
            <div css={styles.tokenIcon}>
              <img src={Images.FitTokenIcon} alt='fit token' />
              <span>FIT</span>
            </div>

            <span>{toFixedNumber(walletBallance.fit)}</span>
          </div>
        </div> */}

        <div css={styles.navigateMenu}>
          {menuNavs.map((menuItem) => (
            <div
              key={menuItem.label}
              css={styles.menuItem}
              onClick={() => handleNavigate(menuItem.url)}
              className={isMatchPath(menuItem.url) ? 'active' : ''}
            >
              <img
                src={
                  isMatchPath(menuItem.url)
                    ? menuItem.activeIcon
                    : menuItem.icon
                }
                alt={menuItem.label}
              />
              {menuItem.label}
            </div>
          ))}
        </div>

        {/* <div css={styles.bottomMenuWrapper}>
          {accessToken && (
            <Button
              onClick={handleLogout}
              css={[commonClass.appButton, styles.signOutBtn]}
            >
              Sign Out
            </Button>
          )}
        </div> */}
      </div>
    )
  }

  return isMobile() ? (
    <Drawer anchor={'left'} open={isOpen} onClose={handleClose}>
      {renderMenuContent()}
    </Drawer>
  ) : (
    renderMenuContent()
  )
}

export default UserMenu
