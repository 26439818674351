import { createTheme } from '@mui/material/styles'
import { css } from '@emotion/react'
import Images from 'images'
export const baseColors = {
  primaryLinearGradient: 'linear-gradient(180deg, #FFBAA1 0%, #FD3F46 100%)',
  primaryColor: '#F89562',
  primaryColor3: '#FEA090',
  primaryColor4: '#FD7D74',

  secondaryColor: '#ABC1EB',
  secondaryColor2: '#D0E0F7',
  secondaryColor3: '#F5F5F5',
  secondaryColor5: '#6D83B4',

  neutralColor1: '#FFFFFF',
  neutralColor2: '#FAFAFA',
  neutralColor6: '#BFBFBF',
  neutralColor7: '#8C8C8C',
  neutralColor12: '#141414',

  darkSecondary30: '#5A5B74',
  darkSecondary80: '#8A8CB2',

  darkLink80: '#9FBBFF',

  darkGreen70: '#9BFFBC',
  darkGray70: '#CCC',

  appBg: '#171828',
  cardBg: '#121426',
  imgBg: '#1B2030',
  toastBg: '#323255',

  borderColor: '#2B3755',

  CommonColor: '#CFDDFF',
  UncommonColor: '#9BFEBC',
  RareColor: '#53ACFF',
  EpicColor: '#D99BFF',
  LegendaryColor: '#FFAA4E',

  performanceColor: '#3E77FF',
  luckColor: '#FEF9C8',
  speedColor: '#F67D5A',
  recoveryColor: '#9BFFBC',

  filterLabel: '#c4c5d9',
  darkPrimary: '#f45c0e',

  progressBarBackground: '#5A5B74',
  progressBarColor1: '#9FBCFF',
  progressBarColor2: '#9BFEBC',
  progressBarColor3: '#FCEE66',
  linkText: '#3ed9fb',

  blur: '#0002',
  white: '#fff',
  black: '#000',

  tagBackground: '#2B3755',

  sellingColor: '#F8E130',
  levelingColor: '#FA803B',
  colddownColor: '#AEA7FF',
  lockedColor: '#00BBF9',
  successColor4: '#56E787',
  warningColor2: '#FEFB9D',
  dangerColor: '#F87C73',

  lifetime: '#12FFE6',

  bgTabBar: '#1B2030',
  switchButton: '#FF1F3A',
  dropdownBg: '#242634',
}

export const globalTheme = createTheme({
  palette: {
    primary: {
      main: baseColors.primaryColor,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: baseColors.neutralColor1,
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'transparent',
        },
      },
    },
  },
})

export const tabletWidth = 1023
export const mobileWidth = 768
export const leftMenuWidth = 236

export const commonClass = {
  appButton: css`
    background-image: url(${Images.ButtonWrapper});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-weight: 600;
    color: ${baseColors.neutralColor1};
    background-color: transparent;
    border: 0;
    cursor: pointer;
    & .MuiCircularProgress-root {
      margin-right: 8px;
      color: ${baseColors.neutralColor1};
    }
    &.Mui-disabled {
      color: ${baseColors.neutralColor1};
      opacity: 0.7;
    }
    &.inactive {
      background-image: url(${Images.ButtonSecondaryWrapper});
    }
    &.outline {
      background-image: url(${Images.ButtonOutlineWrapper});
    }
  `,
  outlineButton: css`
    color: ${baseColors.secondaryColor5};
    border-radius: 36px;
    font-weight: 600;
    border: 2px solid ${baseColors.secondaryColor5};
    background: transparent;
    padding-left: 24px;
    padding-right: 24px;
  `,
  ghostButton: css`
    color: ${baseColors.secondaryColor5};
    font-weight: 600;
    background: transparent;
    text-align: center;
  `,
  hideInMobile: css`
    @media only screen and (max-width: ${tabletWidth}px) {
      display: none !important;
    }
  `,
  hideInDesktop: css`
    @media only screen and (min-width: ${tabletWidth}px) {
      display: none !important;
    }
  `,
  containerSmall: css`
    max-width: 460px;
    margin: 0 auto;
  `,
}
