import {
  Routes,
  Route,
  matchRoutes,
  useLocation,
  Navigate,
} from 'react-router-dom'

import RedirectLogin from 'views/auth/RedirectLogin'
import Trading from 'views/wallet/trading/Trading'
import TransferWallet from 'views/wallet/transfer/Transfer'
import TransferExternal from 'views/wallet/transfer-external/TransferExternal'
import ShoeDetailBag from 'views/bag/ShoeDetailBag'
import MarketList from 'views/market/MarketList'
import ShoeDetailMarket from 'views/market/ShoeDetailMarket'
import BagList from 'views/bag/BagList'
import MintPage from 'views/bag/Mint'
import SpendingWallet from 'views/wallet/spending/SpendingWallet'
import OnChainWallet from 'views/wallet/onchain/OnChainWallet'
import NFTShoeboxList from 'views/wallet/onchain/NFTShoeboxList'
import ShoeboxTransferExternal from 'views/wallet/transfer-external/ShoeboxTransferExternal'
import NFTShoeList from 'views/wallet/onchain/NFTShoeList'
import ShoeTransferExternal from 'views/wallet/transfer-external/ShoeTransferExternal'
interface RouteProps {
  path: string
  element: any
  metaData?: IRouteMeta
}

export const ROUTE_PATHS = {
  DEFAULT: '/',
  REDIRECT_LOGIN: '/redirect-login/',
  MARKET: '/market',
  MARKET_DETAIL: '/market/shoe/:marketItemId',
  BAG: '/bag',
  BAG_DETAIL: '/bag/shoe/:shoeId',
  BAG_MINT: '/bag/shoe/:shoeId/mint',
  WALLET_SPENDING: '/wallet/spending',
  WALLET_ONCHAIN: '/wallet/onchain',
  WALLET_SHOEBOX: '/wallet/shoebox',
  WALLET_SHOE: '/wallet/sneakers',
  WALLET_TRANSFER: '/wallet/transfer',
  WALLET_TRANSFER_EXTERNAL: '/wallet/transfer-external/:tokenType',
  WALLET_TRANSFER_EXTERNAL_SHOEBOX:
    '/wallet/transfer-external-shoebox/:shoeBoxId',
  WALLET_TRANSFER_EXTERNAL_SHOE: '/wallet/transfer-external-sneakers/:shoeId',
  WALLET_TRADING: '/wallet/trade',
}

export interface IRouteMeta {
  noHeader?: boolean
  hideGlobalMenu?: boolean
}

export const ROUTES: RouteProps[] = [
  {
    path: ROUTE_PATHS.REDIRECT_LOGIN,
    element: RedirectLogin,
    metaData: {
      noHeader: true,
    },
  },
  {
    path: ROUTE_PATHS.MARKET,
    element: MarketList,
  },
  {
    path: ROUTE_PATHS.MARKET_DETAIL,
    element: ShoeDetailMarket,
    metaData: {
      hideGlobalMenu: true,
    },
  },

  {
    path: ROUTE_PATHS.BAG,
    element: BagList,
  },

  {
    path: ROUTE_PATHS.BAG_DETAIL,
    element: ShoeDetailBag,
    metaData: {
      hideGlobalMenu: true,
    },
  },
  {
    path: ROUTE_PATHS.BAG_MINT,
    element: MintPage,
    metaData: {},
  },
  {
    path: ROUTE_PATHS.WALLET_SPENDING,
    element: SpendingWallet,
  },
  {
    path: ROUTE_PATHS.WALLET_ONCHAIN,
    element: OnChainWallet,
  },
  {
    path: ROUTE_PATHS.WALLET_SHOEBOX,
    element: NFTShoeboxList,
  },
  {
    path: ROUTE_PATHS.WALLET_SHOE,
    element: NFTShoeList,
  },
  {
    path: ROUTE_PATHS.WALLET_TRANSFER,
    element: TransferWallet,
  },
  {
    path: ROUTE_PATHS.WALLET_TRANSFER_EXTERNAL,
    element: TransferExternal,
  },
  {
    path: ROUTE_PATHS.WALLET_TRANSFER_EXTERNAL_SHOEBOX,
    element: ShoeboxTransferExternal,
  },
  {
    path: ROUTE_PATHS.WALLET_TRANSFER_EXTERNAL_SHOE,
    element: ShoeTransferExternal,
  },
  {
    path: ROUTE_PATHS.WALLET_TRADING,
    element: Trading,
  },
]

const Router = () => {
  return (
    <Routes>
      {ROUTES.map((r) => (
        <Route key={r.path} path={r.path} element={<r.element />} />
      ))}

      <Route
        key='other'
        path='*'
        element={<Navigate to={ROUTE_PATHS.MARKET} replace />}
      />
    </Routes>
  )
}

export const useRouteMetaData = () => {
  const location = useLocation()
  const arr = matchRoutes(ROUTES, location)

  return arr?.[0].route.metaData
}
export default Router
