/** @jsxImportSource @emotion/react */
import { Modal, Box, Button, CircularProgress } from '@mui/material'
import _toLower from 'lodash/toLower'
import _get from 'lodash/get'
import { useCallback, useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import Images from 'images'
import WrapperBgImg from 'components/wrap-background-img/WrapperBgImg'
import { baseColors, commonClass } from 'theme'
import { capitalizeFirstLetter, toFixedNumber } from 'helpers/utils'
import { useAppDispatch, useAppSelector } from 'store'
import { IShoeBox } from 'types/nft'
import MintShoes from 'components/shoe-detail/MintShoes'
import { NFT_TYPE, NFT_STATUS, SOURCES } from 'types/common'
import { revokeMarketItem } from 'store/reducers/marketList'
import { showToast } from 'store/reducers/common'
import { fetchShoeBoxDetail } from 'store/reducers/bag'
import styles from './ShoeboxModal.styles'
interface IProps {
  isOpen: boolean
  handleClose: () => void
  itemDetail: IShoeBox | null
  onSell: Function
  onTransfer: Function
  onOpen: Function
  onRevokeSuccess: Function
}

const ShoeboxModal = ({
  isOpen,
  handleClose,
  itemDetail,
  onSell,
  onTransfer,
  onOpen,
  onRevokeSuccess,
}: IProps) => {
  const dispatch = useAppDispatch()
  const appSource = useAppSelector((state) => state.common.source) || 'OUTDOOR'

  const [loading, setLoading] = useState(false)
  const [shoeBoxDetail, setShoeBoxDetail] = useState(itemDetail)
  const getShoeBoxDetail = useCallback(() => {
    if (itemDetail?.shoeBoxId) {
      dispatch(fetchShoeBoxDetail({ shoeBoxId: itemDetail.shoeBoxId }))
        .then(unwrapResult)
        .then(({ response }) => {
          setShoeBoxDetail(response)
        })
    }
  }, [itemDetail, dispatch])

  useEffect(() => {
    if (!isOpen) {
      setLoading(false)
    }
  }, [isOpen])

  useEffect(() => {
    getShoeBoxDetail()
  }, [itemDetail, getShoeBoxDetail])

  const qualityText = capitalizeFirstLetter(shoeBoxDetail?.quality)
  const qualityColor = _get(baseColors, qualityText + 'Color')
  const qualityBg = (Images.qualityBg as any)[
    _toLower(shoeBoxDetail?.quality) || 'common'
  ] as string

  const imgShoeboxSrc =
    appSource === SOURCES.OUTDOOR
      ? Images.shoebox.outdoor
      : Images.shoebox.indoor
  const shoeboxImg = (imgShoeboxSrc as any)[
    _toLower(shoeBoxDetail?.quality) || 'common'
  ] as string

  const runRevoke = () => {
    setLoading(true)
    const shoeDetail = shoeBoxDetail

    if (shoeDetail?.shoeBoxId) {
      dispatch(
        revokeMarketItem({
          itemId: shoeDetail.shoeBoxId,
          type: NFT_TYPE.SHOEBOX,
        }),
      )
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            onRevokeSuccess()
          } else {
            dispatch(
              showToast({
                title: 'Error',
                message: response.message,
              }),
            )
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box css={styles.wrapper}>
        <>
          <div className='df jcb'>
            <div css={styles.modalTitle}>Shoebox detail</div>
            <div className='pointer' onClick={handleClose}>
              <Images.CloseIcon />
            </div>
          </div>
          <div css={styles.bgWrapper}>
            <WrapperBgImg src={qualityBg}>
              <div css={styles.bgWrapperContent}>
                <img css={styles.shoeImg} src={shoeboxImg} alt='' />

                <div
                  className='mb-2'
                  css={styles.shoeId}
                  style={{ color: qualityColor }}
                >
                  #{shoeBoxDetail?.shoeBoxId}
                </div>
              </div>
            </WrapperBgImg>
          </div>

          {shoeBoxDetail?.status === NFT_STATUS.SELLING &&
            !!shoeBoxDetail?.marketSellTime && (
              <div css={styles.confirmInfo}>
                <div css={styles.confirmInfoItem}>
                  <span>Date</span>
                  <span>
                    {shoeBoxDetail?.marketSellTime
                      ? dayjs(shoeBoxDetail.marketSellTime).format('DD-MM-YYYY')
                      : ''}
                  </span>
                </div>

                <div css={styles.confirmInfoItem}>
                  <span>Price</span>
                  <span>
                    {toFixedNumber(shoeBoxDetail?.marketSellingPrice)}
                    {appSource === SOURCES.OUTDOOR ? 'FIT' : 'IFIT'}
                  </span>
                </div>
              </div>
            )}

          <MintShoes
            style={{ marginTop: 16 }}
            mints={[shoeBoxDetail?.leftShoe, shoeBoxDetail?.rightShoe]}
            title='Minted From'
            wrapStyle={{
              backgroundColor: baseColors.bgTabBar,
              backgroundImage: 'initial',
              borderRadius: 8,
            }}
          />
          {shoeBoxDetail?.status === NFT_STATUS.SELLING && (
            <div css={styles.actionOpen} className='mt-3 mx-auto'>
              <Button
                css={[commonClass.appButton, styles.openBtn]}
                onClick={() => runRevoke()}
                disabled={loading}
              >
                {!!loading && <CircularProgress size={18} />}
                REVOKE
              </Button>
            </div>
          )}
          {shoeBoxDetail?.status === NFT_STATUS.CREATED && (
            <>
              <div css={styles.actions} className='mt-3 mx-auto'>
                <Button
                  onClick={() => onSell()}
                  css={[commonClass.ghostButton, styles.smallBtn]}
                >
                  SELL
                </Button>
                <Button
                  css={[commonClass.ghostButton, styles.smallBtn]}
                  onClick={() => onTransfer()}
                  disabled={loading}
                >
                  {!!loading && <CircularProgress size={18} />}
                  Transfer
                </Button>
              </div>
              <div css={styles.actionOpen} className='mt-3 mx-auto'>
                <Button
                  css={[commonClass.appButton, styles.openBtn]}
                  onClick={() => onOpen()}
                  disabled={loading}
                >
                  {!!loading && <CircularProgress size={18} />}
                  OPEN NOW
                </Button>
              </div>
            </>
          )}
        </>
      </Box>
    </Modal>
  )
}

export default ShoeboxModal
