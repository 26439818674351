import { css } from '@emotion/react'
import { baseColors, mobileWidth, leftMenuWidth } from 'theme'

const styles = {
  logo: css`
    gap: 15px;
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 16px;
  `,
  wrapAds: css`
    padding: 0 16px;
  `,
  menuWrapper: css`
    width: ${leftMenuWidth}px;
    height: calc(100vh - 32px);
    background-color: ${baseColors.cardBg};
    color: ${baseColors.neutralColor1};
    position: relative;
    padding: 16px 0;
    @media only screen and (min-width: ${mobileWidth}px) {
      position: absolute;
      top: 0;
      left: 0;
    }
  `,
  userInfo: css`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
  `,
  userFullName: css``,
  userEmail: css``,

  walletInfo: css`
    background-color: ${baseColors.imgBg};
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 0 12px;
  `,
  walletInfoItem: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 0;
    border-top: 1px solid #2c395b;
    &:first-of-type {
      border: 0;
    }
  `,
  tokenIcon: css`
    display: flex;
    align-items: center;
    img {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }
  `,
  navigateMenu: css``,
  menuItem: css`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 24px;
    border-top: 1px solid #10192f;
    cursor: pointer;
    &:first-of-type {
      border: 0;
    }

    img {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
    &.active {
      background-color: rgba(3, 7, 26, 0.36);
      color: white;
      font-weight: bold;
      svg path {
        stroke: white;
      }
    }

    svg {
      margin-left: auto;
    }
  `,
  bottomMenuWrapper: css`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%);
  `,
  signOutBtn: css`
    width: 126px;
  `,

  label: css`
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 11px;
    /* Secondary Color/03 */
    color: #738dc3;
  `,
  ads: css`
    padding: 1px;
    border-radius: 2px;
    height: 54px;
    margin-bottom: 12px;
    cursor: pointer;
    &:hover {
    }
  `,
  adsInner: css`
    border-radius: 2px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 8px;
    gap: 12px;
  `,

  ads1: css`
    background: linear-gradient(to right, #07345c 0%, #121426 100%);
  `,
  adsInner1: css`
    background: linear-gradient(
      83.45deg,
      #112f42 2.39%,
      rgba(48, 48, 48, 0) 27.67%,
      #081e2f 114.73%
    );
  `,
  ads2: css`
    background: linear-gradient(to right, #604a11 0%, #121426 100%);
  `,
  adsInner2: css`
    background: linear-gradient(
      83.45deg,
      #351c14 2.39%,
      rgba(48, 48, 48, 0) 27.67%,
      #372f10 114.73%
    );
  `,
  ads3: css`
    background: linear-gradient(to right, #60112d 0%, #121426 100%);
  `,
  adsInner3: css`
    background: linear-gradient(
      83.45deg,
      #351428 2.39%,
      rgba(48, 48, 48, 0) 27.67%,
      #37101c 114.73%
    );
  `,
}

export default styles
