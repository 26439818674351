import { css } from '@emotion/react'
import { baseColors, mobileWidth } from 'theme'
const styles = {
  wrapper: css`
    background-color: ${baseColors.cardBg};
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(95vw - 48px);
    border-radius: 4px;

    max-height: 90vh;
    overflow: auto;
    @media only screen and (min-width: ${mobileWidth}px) {
      width: 500px;
    }
  `,

  bgWrapper: css`
    width: calc(95vw - 48px);
    height: calc((95vw - 48px) * 0.6615);

    @media only screen and (min-width: ${mobileWidth}px) {
      width: 500px;
      height: calc(500px * 0.6615);
    }
  `,
  bgWrapperContent: css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  `,
  shoeImg: css`
    width: 50%;
    margin-bottom: -30px;
  `,
  shoeId: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
  `,

  modalTitle: css`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${baseColors.neutralColor1};
  `,

  wrapperImg: css`
    height: 600px;
  `,
  mintWrapper: css`
    width: calc(100% - 12px);
    max-width: 400px;
  `,
  mintText: css`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${baseColors.secondaryColor2};
    margin-bottom: 2px;
  `,

  actions: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 12px);
    max-width: 400px;
  `,
  cancelBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  confirmBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  shoeInfoWrapper: css`
    width: 90%;
  `,
  priceInputWrapper: css`
    margin-top: 16px;
  `,
  priceLabel: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  `,
  priceInput: css`
    width: 100%;
    height: 52px;
    .MuiInputBase-root {
      background: ${baseColors.appBg};
      border-radius: 12px;
      margin-top: 12px;
    }
    * {
      color: ${baseColors.neutralColor1} !important;
      border: 0 !important;
      outline: none !important;
      box-shadow: none !important;
    }
  `,

  confirmInfo: css`
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  `,
  confirmInfoItem: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    background: ${baseColors.appBg};
    padding: 16px;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  `,
  shoeItem: css`
    height: 112px;
    width: 112px;
    background: ${baseColors.appBg};
    border: 1px solid ${baseColors.appBg};
    border-radius: 16px;
    cursor: pointer;
    &:hover {
      border-color: ${baseColors.primaryColor4};
    }
    img {
      height: 90%;
      width: 90%;
    }
  `,
  shoeItemActive: css`
    border-color: ${baseColors.primaryColor4};
  `,
  shoeList: css`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    height: 240px;
    overflow: auto;
  `,
  noSelect: css`
    background: ${baseColors.appBg};
    border-radius: 20px;
    img {
      width: 100%;
    }
  `,
  noSelectText: css`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    margin-bottom: 30px;
  `,
}

export default styles
