/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
} from '@mui/material'
import { Global } from '@emotion/react'
import { SelectChangeEvent } from '@mui/material/Select'
import { find } from 'lodash'
import { formatNumber } from 'helpers/formatBalance'
import { TOKEN_TYPE, NFT_TYPE, NFT_STATUS, SOURCES } from 'types/common'
import { WITHDRAW_STEPS, IWithdrawFee } from 'types/wallet'
import { commonClass } from 'theme'
import { fetchShoeBagList, fetchShoeBoxBagList } from 'store/reducers/bag'
import { fetchWalletBallance, getWithdrawFee } from 'store/reducers/wallet'
import {
  withdrawShoe,
  withdrawShoebox,
  withdrawCaloFit,
  withdrawCaloIFit,
  withdrawShoeIndoor,
  withdrawShoeboxIndoor,
} from 'api/smc'
import { useAppDispatch, useAppSelector } from 'store'
import Images from 'images'
import { showToast } from 'store/reducers/common'
import WithdrawConfirmModal from './WithdrawConfirmModal'

import styles from './Transfer.styles'

interface ITokenSelect {
  type: string
  image?: string
  id?: number
  shoeId?: number
  shoeBoxId?: number
  quality?: string
}

const Withdraw = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const userInfo = useAppSelector((state) => state.auth.userInfo)
  const walletInApp = useAppSelector((state) => state.wallet)
  const shoeList = useAppSelector((state) => state.bag.shoeData) || []
  const shoeboxList = useAppSelector((state) => state.bag.shoeBoxData) || []
  const appSource = useAppSelector((state) => state.common.source) || 'OUTDOOR'

  const [tokenBalance, setTokenBalance] = useState(0)
  const [isOpenConfirm, setIsOpenConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [withdrawFee, setWithdrawFee] = useState<IWithdrawFee>({
    caloAmount: '0',
    fitAmount: '0',
    ifitAmount: '0',
  })
  const [tokenSelected, setTokenSelected] = useState<ITokenSelect>()
  const [amount, setAmount] = useState<string>()
  const [step, setStep] = useState(WITHDRAW_STEPS.INFO)
  const [withdrawOTP, setWithdrawOTP] = useState<string>('')

  useEffect(() => {
    dispatch(
      fetchShoeBagList({
        limit: 1000,
        offset: 0,
        type: NFT_TYPE.SHOE,
      }),
    )
    dispatch(
      fetchShoeBoxBagList({
        limit: 1000,
        offset: 0,
        type: NFT_TYPE.SHOEBOX,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetTokenBalance = (token: ITokenSelect) => {
    let balance = 0

    switch (token.type) {
      case TOKEN_TYPE.CALO:
        balance = walletInApp.calo || 0
        break
      case TOKEN_TYPE.FIT:
        balance = walletInApp.fit || 0
        break
      case TOKEN_TYPE.IFIT:
        balance = walletInApp.ifit || 0
        break
      default:
        balance = 1
    }

    setTokenBalance(balance)
  }

  const handleTokenChange = (event: SelectChangeEvent) => {
    const token = JSON.parse(event.target.value) || {}

    if (token.type === NFT_TYPE.SHOE || token.type === NFT_TYPE.SHOEBOX) {
      setAmount('1')
    } else {
      setAmount('')
    }

    handleSetTokenBalance(token)
    setTokenSelected(token)
  }

  const handleShowConfirmTransfer = () => {
    setStep(WITHDRAW_STEPS.INFO)
    setWithdrawOTP('')

    dispatch(
      getWithdrawFee({
        tokenType: tokenSelected?.type || '',
        amount: amount || '1',
      }),
    )
      .then(unwrapResult)
      .then(({ response }: any) => {
        if (response.apiStatus) {
          const fee = find(response?.data, (tk) => {
            if (
                tk.tokenType === TOKEN_TYPE.CALO ||
                tk.tokenType === TOKEN_TYPE.FIT ||
                tk.tokenType === TOKEN_TYPE.IFIT
            ) {
              return tk.tokenType === tokenSelected?.type
            }

            return (
                tk.tokenType === tokenSelected?.type &&
                tk.quality === tokenSelected?.quality
            )
          })

          setWithdrawFee(fee)
          setIsOpenConfirm(true)
        } else {
          dispatch(
            showToast({
              title: 'Error',
              message: response.message,
            }),
          )
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleConfirmTransferOutdoor = async () => {
    if (account && !!userInfo.caloId && tokenSelected && amount) {
      setLoading(true)

      try {
        let tx

        if (
          tokenSelected?.type === TOKEN_TYPE.CALO ||
          tokenSelected?.type === TOKEN_TYPE.FIT
        ) {
          tx = await withdrawCaloFit(
            account,
            tokenSelected.type,
            amount,
            withdrawOTP,
          )
          dispatch(fetchWalletBallance({}))
        }
        if (tokenSelected?.shoeId && tokenSelected?.type === NFT_TYPE.SHOE) {
          tx = await withdrawShoe(
            account,
            NFT_TYPE.SHOE,
            tokenSelected.shoeId,
            withdrawOTP,
          )
          dispatch(
            fetchShoeBagList({
              limit: 1000,
              offset: 0,
              type: NFT_TYPE.SHOE,
            }),
          )
        }

        if (
          tokenSelected?.shoeBoxId &&
          tokenSelected?.type === NFT_TYPE.SHOEBOX
        ) {
          tx = await withdrawShoebox(
            account,
            NFT_TYPE.SHOEBOX,
            tokenSelected.shoeBoxId,
            withdrawOTP,
          )
          dispatch(
            fetchShoeBoxBagList({
              limit: 1000,
              offset: 0,
              type: NFT_TYPE.SHOE,
            }),
          )
        }

        if (tx) {
          dispatch(
            showToast({
              title: 'Transfer success',
              message:
                'Transaction has submitted. This progress will take (5-10 minutes) to finish.',
            }),
          )
          setAmount('')
          setTokenSelected(undefined)
        }
      } catch (error: any) {
        let message = ''

        if (error?.apiStatus === 0) {
          message = error.message
        } else if (error?.message?.includes('rejected transaction')) {
          message = 'User denied transaction signature'
        } else {
          message = 'Something went wrong'
        }

        dispatch(
          showToast({
            title: 'Transfer error',
            message: message || 'Something went wrong',
          }),
        )
      }
      setLoading(false)
      setIsOpenConfirm(false)
    }
  }

  const handleConfirmTransferIndoor = async () => {
    if (account && !!userInfo.caloId && tokenSelected && amount) {
      setLoading(true)

      try {
        let tx

        if (
          tokenSelected?.type === TOKEN_TYPE.CALO ||
          tokenSelected?.type === TOKEN_TYPE.IFIT
        ) {
          tx = await withdrawCaloIFit(
            account,
            tokenSelected.type,
            amount,
            withdrawOTP,
          )
          dispatch(fetchWalletBallance({}))
        }
        if (tokenSelected?.shoeId && tokenSelected?.type === NFT_TYPE.SHOE) {
          tx = await withdrawShoeIndoor(
            account,
            NFT_TYPE.SHOE,
            tokenSelected.shoeId,
            withdrawOTP,
          )
          dispatch(
            fetchShoeBagList({
              limit: 1000,
              offset: 0,
              type: NFT_TYPE.SHOE,
            }),
          )
        }

        if (
          tokenSelected?.shoeBoxId &&
          tokenSelected?.type === NFT_TYPE.SHOEBOX
        ) {
          tx = await withdrawShoeboxIndoor(
            account,
            NFT_TYPE.SHOEBOX,
            tokenSelected.shoeBoxId,
            withdrawOTP,
          )
          dispatch(
            fetchShoeBoxBagList({
              limit: 1000,
              offset: 0,
              type: NFT_TYPE.SHOE,
            }),
          )
        }

        if (tx) {
          dispatch(
            showToast({
              title: 'Transfer success',
              message:
                'Transaction has submitted. This progress will take (5-10 minutes) to finish.',
            }),
          )
          setAmount('')
          setTokenSelected(undefined)
        }
      } catch (error: any) {
        let message = ''

        if (error?.apiStatus === 0) {
          message = error.message
        } else if (error?.message?.includes('rejected transaction')) {
          message = 'User denied transaction signature'
        } else {
          message = 'Something went wrong'
        }

        dispatch(
          showToast({
            title: 'Transfer error',
            message: message || 'Something went wrong',
          }),
        )
      }
      setLoading(false)
      setIsOpenConfirm(false)
    }
  }

  const renderAvailableToken = () => {
    if (tokenSelected) {
      if (
        tokenSelected?.type === TOKEN_TYPE.CALO ||
        tokenSelected?.type === TOKEN_TYPE.FIT ||
        tokenSelected?.type === TOKEN_TYPE.IFIT
      ) {
        return (
          <div css={styles.availableBalance}>
            Available: {tokenBalance > 0 ? formatNumber(tokenBalance, 2) : 0}{' '}
            {tokenSelected?.type}
          </div>
        )
      }

      return null
    }
  }

  const tokenList = [
    {
      type: TOKEN_TYPE.CALO,
      image: Images.CaloTokenIcon,
    },
  ]

  if (appSource === SOURCES.INDOOR) {
    tokenList.push({
      type: TOKEN_TYPE.IFIT,
      image: Images.IfitTokenIcon,
    })
  } else {
    tokenList.push({
      type: TOKEN_TYPE.FIT,
      image: Images.FitTokenIcon,
    })
  }

  const formInvalid =
    !tokenSelected || !amount || loading || +amount > tokenBalance

  return (
    <div css={styles.formWrapper}>
      <div css={styles.formControl}>
        <FormControl fullWidth>
          <div css={styles.formLabel}>Asset</div>
          <Global styles={styles.dropdownMenu} />
          <Select
            css={styles.inputWrapper}
            value={JSON.stringify(tokenSelected)}
            onChange={handleTokenChange}
            inputProps={{ 'aria-label': 'Without label' }}
            MenuProps={{
              className: 'calo-dropdown-menu',
            }}
            IconComponent={Images.ArrowDown}
          >
            {tokenList?.length > 0 &&
              tokenList.map((item: ITokenSelect) => {
                return (
                  <MenuItem
                    value={JSON.stringify(item)}
                    css={styles.selectItem}
                    key={item.type}
                  >
                    <img src={item.image} alt={item.type} className='icon' />{' '}
                    {item.type}
                  </MenuItem>
                )
              })}
            {shoeList?.length > 0 &&
              shoeList.map((item: any) => {
                if (item?.status !== NFT_STATUS.COOLDOWN) {
                  return (
                    <MenuItem
                      value={JSON.stringify({ ...item, type: NFT_TYPE.SHOE })}
                      css={styles.selectItem}
                      key={item.shoeId}
                    >
                      <img
                        src={item.image}
                        alt={item.shoeId}
                        className='icon'
                      />{' '}
                      {`${item.class} ${item.quality} #${item.shoeId}`}
                    </MenuItem>
                  )
                }

                return null
              })}
            {shoeboxList?.length > 0 &&
              shoeboxList.map((item: any) => {
                return (
                  <MenuItem
                    value={JSON.stringify({ ...item, type: NFT_TYPE.SHOEBOX })}
                    css={styles.selectItem}
                    key={item.shoeBoxId}
                  >
                    <img
                      src={item.image}
                      alt={item.shoeBoxId}
                      className='icon'
                    />{' '}
                    {`SHOEBOX ${item.quality} #${item.shoeBoxId}`}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
      </div>
      <div css={styles.formControl}>
        <FormControl fullWidth>
          <div css={styles.formLabel}>Amount</div>
          <OutlinedInput
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            css={styles.inputWrapper}
            disabled={
              tokenSelected?.type === NFT_TYPE.SHOE ||
              tokenSelected?.type === NFT_TYPE.SHOEBOX
            }
          />
          {tokenSelected && renderAvailableToken()}
        </FormControl>
      </div>
      <div css={styles.formControl}>
        <Button
          onClick={handleShowConfirmTransfer}
          css={[commonClass.appButton, styles.confirmBtn]}
          disabled={formInvalid}
        >
          CONFIRM TRANSFER
        </Button>
      </div>
      <WithdrawConfirmModal
        otp={withdrawOTP}
        setOtp={setWithdrawOTP}
        step={step}
        setStep={setStep}
        fee={withdrawFee}
        token={tokenSelected}
        amount={amount}
        isLoading={loading}
        isOpen={isOpenConfirm}
        onClose={() => setIsOpenConfirm(false)}
        onConfirm={
          appSource === SOURCES.OUTDOOR
            ? handleConfirmTransferOutdoor
            : handleConfirmTransferIndoor
        }
      />
    </div>
  )
}

export default Withdraw
