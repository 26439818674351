/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store'
import { fetchMarketItem } from 'store/reducers/marketList'
import { CUSTOM_EVENTS } from 'types/common'
import ShoeDetail from 'components/shoe-detail/ShoeDetail'
import BuyModal from 'components/buy-modal/BuyModal'
import { showToast } from 'store/reducers/common'
import { IShoe } from 'types/nft'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import styles from './ShoeDetailMarket.styles'

const ShoeDetailMarket = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { marketItemId } = useParams()
  const shoeDetail = useAppSelector((state) => state.marketList.shoeDetail)
  const [isOpenConfirmBuy, setOpenConfirmBuy] = useState(false)
  const handleBuyClick = () => {
    if (shoeDetail?.owner) {
      dispatch(
        showToast({
          title: 'You are the owner of this item',
        }),
      )
    } else {
      setOpenConfirmBuy(true)
    }
  }
  const onBuySuccess = () => {
    dispatch(
      showToast({
        title: 'Transaction Successful!',
        message: 'Check your bag to start using the item.',
      }),
    )
    navigate(-1)
  }

  useEffect(() => {
    document.addEventListener(CUSTOM_EVENTS.REFRESH_CLICKED, handleRefreshData)
    handleRefreshData()

    return () => {
      document.removeEventListener(
        CUSTOM_EVENTS.REFRESH_CLICKED,
        handleRefreshData,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketItemId])

  const handleRefreshData = () => {
    if (marketItemId) {
      dispatch(
        fetchMarketItem({
          marketItemId: +marketItemId,
        }),
      )
    }
  }

  return (
    <div css={styles.wrapper}>
      <Breadcrumb
        value={[
          {
            url: '/market',
            label: 'Market',
          },
          {
            label: 'Sneaker details',
          },
        ]}
      />
      {!!shoeDetail?.base && (
        <ShoeDetail
          shoeDetail={shoeDetail.base as IShoe}
          marketItemDetail={shoeDetail}
          onBuyClick={handleBuyClick}
        />
      )}

      <BuyModal
        itemDetail={shoeDetail || null}
        isOpen={isOpenConfirmBuy}
        handleClose={() => setOpenConfirmBuy(false)}
        onBuySuccess={onBuySuccess}
        type={shoeDetail?.type || ''}
      />
    </div>
  )
}

export default ShoeDetailMarket
