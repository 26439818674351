/** @jsxImportSource @emotion/react */
import { Modal, Box, Button, CircularProgress } from '@mui/material'
import Images from 'images'
import { commonClass } from 'theme'
import { formatNumber } from 'helpers/formatBalance'
import styles from './ConfirmModal.styles'

interface IProps {
  data: any
  isLoading: boolean
  isOpen: boolean
  onConfirm: () => void
  setShowModal: (value: boolean) => void
}

const TradeConfirmModal = ({
  data,
  isLoading,
  isOpen,
  onConfirm,
  setShowModal,
}: IProps) => {
  const { tokenFrom, tokenTo, amountEstimate } = data

  return (
    <Modal open={isOpen} onClose={() => setShowModal(false)}>
      <Box css={styles.modalWrapper}>
        <div className='df jcb'>
          <div css={styles.modalTitle}>Confirm Trade</div>
          <div className='pointer' onClick={() => setShowModal(false)}>
            <Images.CloseIcon />
          </div>
        </div>

        <div css={styles.boxDirection}>
          <div css={styles.directionItem}>
            <div css={styles.directionFrom}>From</div>
            <div css={styles.directionFrom}>To</div>
          </div>
          <div css={styles.directionItem}>
            <div css={styles.directionTo}>{tokenFrom?.name}</div>
            <div css={styles.directionTo}>{tokenTo?.name}</div>
          </div>
        </div>
        <div css={styles.transferToken}>
          <div css={styles.transferItem}>
            <div css={styles.transferItemLabel}>Received</div>
            <div css={styles.transferItemValue}>
              {formatNumber(amountEstimate, 5) || 0}
            </div>
          </div>
        </div>
        <div css={styles.actions} className='mt-5 mx-auto'>
          <Button
            css={[commonClass.ghostButton, styles.cancelBtn]}
            onClick={() => setShowModal(false)}
          >
            CANCEL
          </Button>
          <Button
            onClick={onConfirm}
            css={[commonClass.appButton, styles.confirmBtn]}
            disabled={isLoading}
          >
            {!!isLoading && <CircularProgress size={18} />}
            CONFIRM
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

export default TradeConfirmModal
