/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import _startsWith from 'lodash/startsWith'
import Images from 'images'
import { CUSTOM_EVENTS, SOURCE_TYPE } from 'types/common'
import { useAppDispatch, useAppSelector } from 'store'
import ModalLogin from 'components/login-modal'
import { ROUTE_PATHS, useRouteMetaData } from 'routes'
import { toFixedNumber } from 'helpers/utils'
// import { fetchWalletBallance } from 'store/reducers/wallet'
import { changeSource } from 'store/reducers/common'
import { baseColors } from 'theme'
import { removeUserToken } from 'store/reducers/auth'
import UserMenu from './UserMenu'
import styles from './Header.styles'

const Header = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const routeMetaData = useRouteMetaData()
  const walletBallance = useAppSelector((state) => state.wallet)
  const [openMenu, setOpenMenu] = useState(false)
  const handleOpenUserMenu = () => {
    setOpenMenu(true)
  }

  const handleCloseUserMenu = () => {
    setOpenMenu(false)
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    handleClose()
    dispatch(removeUserToken())
  }

  // const handleRefresh = () => {
  //   dispatch(fetchWalletBallance({}))
  //   document.dispatchEvent(new Event(CUSTOM_EVENTS.REFRESH_CLICKED))
  // }
  const accessToken = useAppSelector((state) => state.auth.token)
  const userInfo = useAppSelector((state) => state.auth.userInfo)
  const sourceState = useAppSelector((state) => state.common.source)

  // const runLogin = () => {
  //   openLogin(true)
  // }
  const [isLogin, openLogin] = useState(false)

  useEffect(() => {
    if (accessToken) {
      openLogin(false)
      document.dispatchEvent(new Event(CUSTOM_EVENTS.REFRESH_CLICKED))
    } else {
      openLogin(true)
    }
  }, [accessToken])

  const menuNavs = [
    {
      icon: Images.MenuIcon,
      activeIcon: Images.MenuIcon,
      label: 'Menu',
      onClick: handleOpenUserMenu,
      url: '',
    },
    {
      icon: Images.MarketIcon,
      activeIcon: Images.MarketActiveIcon,
      label: 'Market',
      url: ROUTE_PATHS.MARKET,
    },
    {
      icon: Images.BagIcon,
      activeIcon: Images.BagActiveIcon,
      label: 'Bag',
      url: ROUTE_PATHS.BAG,
    },
  ]

  const isMatchPath = (path: string) => {
    return _startsWith(location.pathname, path)
  }

  const changeSourceState = (source: SOURCE_TYPE) => {
    dispatch(changeSource(source))
    navigate('/market')
    window.location.reload()
  }

  const renderMenuMobile = () => {
    return (
      <div css={styles.mobile}>
        <div css={styles.platformMobile}>
          <Link to='/market/indoor'>
            <img src={Images.indoor} />
          </Link>
          <img src={Images.headerLine} />
          <Link to='/market/outdoor'>
            <img src={Images.outdoorActive} />
          </Link>
        </div>
        <div style={{ flex: 1 }} />

        <div css={styles.mobileProfile}>
          <div css={styles.mobileProfileItem}>
            <img src='/assets/images/avatar.png' />
            <div>
              <h5>{userInfo?.fullName}</h5>
              <p>{userInfo?.email}</p>
            </div>
            <Images.ArrowRight />
          </div>
          <div css={styles.mobileWallet}>
            <div css={styles.mobileWalletInfo}>
              <div css={styles.mobileWalletCoin}>
                <img src={Images.CaloTokenIcon} alt='calo token' />
              </div>
              <span>{toFixedNumber(walletBallance.calo)}</span>
              <img src={Images.span} />
            </div>
            <Link to='/wallet/spending'>
              <div css={styles.mobileWalletLink}>
                <img src={Images.wallet} />
                Wallet
              </div>
            </Link>
          </div>
        </div>
        <div css={styles.mobileLogout}>
          <Images.LogoutIcon />
          Logout
        </div>
      </div>
    )
  }
  const [isMenuMobile, openMenuMobile] = useState(false)

  if (!!routeMetaData?.noHeader) {
    return null
  }

  return (
    <div>
      <div css={styles.headerStyles}>
        <div css={styles.wrapper}>
          <div css={styles.inner}>
            <div css={styles.logo}>
              <Link to={ROUTE_PATHS.MARKET}>
                <img height={40} src={Images.GlobalMarketLogo} alt='' />
              </Link>
            </div>
            <div css={styles.desktop}>
              <div css={styles.platform}>
                <a
                  className='pointer'
                  onClick={() => changeSourceState('INDOOR')}
                >
                  <img
                    src={
                      sourceState === 'INDOOR'
                        ? Images.indoorActive
                        : Images.indoor
                    }
                  />
                </a>
                <img src={Images.headerLine} />
                <a
                  className='pointer'
                  onClick={() => changeSourceState('OUTDOOR')}
                >
                  <img
                    src={
                      sourceState === 'OUTDOOR'
                        ? Images.outdoorActive
                        : Images.outdoor
                    }
                  />
                </a>
              </div>
              <div style={{ flex: 1 }} />

              <div css={styles.profile}>
                <div css={styles.walletInfo}>
                  <div css={styles.walletInfoItem}>
                    <div css={styles.tokenIcon}>
                      <img src={Images.CaloTokenIcon} alt='calo token' />
                    </div>

                    <span>{toFixedNumber(walletBallance.calo)}</span>
                    <img src={Images.span} />
                  </div>
                </div>
                <Link to='/wallet/spending'>
                  <div css={styles.walletLink}>
                    <img src={Images.wallet} />
                    Wallet
                  </div>
                </Link>
              </div>
            </div>
              <Button
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <div
                  css={styles.avatar}
                  onClick={() => openMenuMobile(!isMenuMobile)}
                >
                  <img src='/assets/images/avatar.png' />
                </div>
              </Button>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                sx={{
                  '& ul': {
                    backgroundColor: baseColors.dropdownBg,
                    color: 'white',
                    borderRadius: 0,
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
          </div>
          {isMenuMobile && renderMenuMobile()}
        </div>

        <UserMenu isOpen={openMenu} handleClose={handleCloseUserMenu} />
        <ModalLogin
          open={isLogin}
          handleClose={() => {
            openLogin(false)
          }}
        />
      </div>
      {!routeMetaData?.hideGlobalMenu && (
        <div css={styles.footerStyle}>
          <div css={styles.footerMenuWrapper}>
            {menuNavs.map((menuItem) => (
              <div
                key={menuItem.label}
                css={[
                  styles.menuItem,
                  menuItem.url && isMatchPath(menuItem.url)
                    ? styles.menuItemActive
                    : '',
                ]}
                onClick={
                  menuItem.onClick
                    ? menuItem.onClick
                    : () => navigate(menuItem.url)
                }
                className={
                  menuItem.url && isMatchPath(menuItem.url) ? 'active' : ''
                }
              >
                <img
                  src={
                    menuItem.url && isMatchPath(menuItem.url)
                      ? menuItem.activeIcon
                      : menuItem.icon
                  }
                  alt={menuItem.label}
                />
                <div>
                  <span>{menuItem.label}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Header
