import { css } from '@emotion/react'
import { tabletWidth } from 'theme'

const styles = {
  tabWrapper: css`
    height: 36px;
    padding: 2px;
    display: flex;
    justify-content: space-between;
    gap: 16px;

    @media only screen and (min-width: ${tabletWidth}px) {
      width: 300px;
    }
  `,
  tabBtn: css`
    &.MuiToggleButton-root {
      background-color: #161827;
      color: rgba(255, 255, 255, 0.5);
      flex: 1;
      text-transform: initial;
      border: 0;
      color: '#8C8C8C';
      font-size: 14px;
      border-radius: 0;
      &.Mui-selected {
        background-color: #2a2c3f;
        font-weight: 600;
        color: white;
      }
    }
  `,
}

export default styles
