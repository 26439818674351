import { css } from '@emotion/react'
import { baseColors } from 'theme'
const styles = {
  wrapper: css`
    display: flex;
    justify-content: space-between;
    height: 24px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    max-width: 400px;
    span {
      transform: skew(20deg);
      display: inline-block;
    }
  `,
  level: css`
    width: 33%;
    background-color: #2b3755;
    height: 100%;
    transform: skew(-20deg);
  `,

  class: css`
    width: 33%;
    background-color: #2b3755;
    height: 100%;
    transform: skew(-20deg);
    svg {
      transform: skew(20deg);
    }
  `,

  durableWrapper: css`
    position: relative;
    display: flex;
    overflow: hidden;
    width: 33%;
    background: #2b3755;
    height: 24px;
    justify-items: center;
    transform: skew(-20deg);
  `,
  durableFront: css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #2b3755;
    color: ${baseColors.neutralColor1};
  `,
  durableBack: css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: ${baseColors.neutralColor12};
  `,
}

export default styles
