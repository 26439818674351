/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ArrowLeft } from 'iconsax-react'
import { Button, FormControl, OutlinedInput } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { formatNumber } from 'helpers/formatBalance'
import { commonClass } from 'theme'
import { useAppDispatch, useAppSelector } from 'store'
import {
  fetchBNBBalance,
  fetchCaloOnchainBallance,
  fetchFitOnchainBallance,
  fetchIFitOnchainBalance,
} from 'store/reducers/walletOnchain'
import { showToast } from 'store/reducers/common'
import {
  getCaloContract,
  getFitContract,
  getIFitContract,
} from 'utils/contractHelpers'
import styles from './TransferExternal.styles'
import { tokenList } from './ModalSelectToken'
import TransferConfirmModal from './TransferConfirmModal'

interface ITokenSelect {
  type: string
  image?: string
  id?: number
  shoe_id?: number
  shoeBoxId?: number
}

const TransferExternal = () => {
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()
  const { tokenType } = useParams()

  const transferToken = tokenList.find((t) => t.type === tokenType)

  const walletOnchain = useAppSelector((state) => state.walletOnchain)

  const [tokenBalance, setTokenBalance] = useState(0)
  const [address, setAddress] = useState<string>('')
  const [amount, setAmount] = useState('0')
  const [loading, setLoading] = useState(false)
  const [isOpenConfirm, setIsOpenConfirm] = useState(false)

  const handleChangeAmt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value)
  }

  const handleSetTokenBalance = (token: ITokenSelect) => {
    let balance = 0

    switch (token.type) {
      case 'BNB':
        balance = walletOnchain.bnb || 0
        break
      case 'CALO':
        balance = walletOnchain.calo || 0
        break
      case 'FIT':
        balance = walletOnchain.fit || 0
        break
      case 'IFIT':
        balance = walletOnchain.ifit || 0
        break
      default:
        balance = 1
    }

    setTokenBalance(balance)
  }

  const renderAvailableToken = () => {
    if (transferToken) {
      if (
        transferToken?.type === 'BNB' ||
        transferToken?.type === 'CALO' ||
        transferToken?.type === 'FIT' ||
        transferToken?.type === 'IFIT'
      ) {
        return (
          <div css={styles.availableBalance}>
            Available: {tokenBalance > 0 ? formatNumber(tokenBalance, 2) : 0}{' '}
            {transferToken?.type}
          </div>
        )
      }

      return null
    }
  }

  const getTokensBalance = async (account: string) => {
    await dispatch(fetchCaloOnchainBallance(account))
    await dispatch(fetchFitOnchainBallance(account))
    await dispatch(fetchBNBBalance(account))
    await dispatch(fetchIFitOnchainBalance(account))
  }

  const resetData = () => {
    setAmount('0')
    setAddress('')
  }

  const handleConfirmTransfer = async () => {
    if (account && transferToken && amount) {
      setLoading(true)
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()

        if (transferToken?.type === 'BNB') {
          const txObj = {
            from: account,
            to: address,
            value: ethers.utils.parseEther(amount.toString()),
          }

          try {
            const txGasLimit = await signer.estimateGas(txObj)
            const txGasPrice = await signer.getGasPrice()
            const gasPrice = ethers.utils.hexlify(txGasPrice)
            const gasLimit = ethers.utils.hexlify(txGasLimit)

            await signer.sendTransaction({
              ...txObj,
              gasLimit,
              gasPrice,
            })
            await getTokensBalance(account)
            setLoading(false)
            setIsOpenConfirm(false)
            resetData()
            dispatch(
              showToast({
                title:
                  'Transaction has submitted. This progress will take some time to finish.',
              }),
            )
          } catch (error) {
            setLoading(false)
            dispatch(
              showToast({
                title: 'Error, please try again',
              }),
            )
          }
        } else if (transferToken?.type === 'CALO') {
          const contract = getCaloContract(signer)

          try {
            const numberOfTokens = ethers.utils.parseUnits(
              amount.toString(),
              18,
            )
            const tx = await contract.transfer(address?.trim(), numberOfTokens)

            await tx.wait()
            await getTokensBalance(account)
            setLoading(false)
            setIsOpenConfirm(false)
            resetData()
            dispatch(
              showToast({
                title:
                  'Transaction has submitted. This progress will take some time to finish.',
              }),
            )
          } catch (error) {
            setLoading(false)
            dispatch(
              showToast({
                title: 'Error, please try again',
              }),
            )
          }
        } else if (transferToken?.type === 'FIT') {
          const contract = getFitContract(signer)

          try {
            const numberOfTokens = ethers.utils.parseUnits(
              amount.toString(),
              18,
            )

            await contract.transfer(address?.trim(), numberOfTokens)
            await getTokensBalance(account)
            setIsOpenConfirm(false)
            setLoading(false)
            resetData()
            dispatch(
              showToast({
                title:
                  'Transaction has submitted. This progress will take some time to finish.',
              }),
            )
          } catch (error) {
            setLoading(false)
            dispatch(
              showToast({
                title: 'Error, please try again',
              }),
            )
          }
        } else if (transferToken?.type === 'IFIT') {
          const contract = getIFitContract(signer)

          try {
            const numberOfTokens = ethers.utils.parseUnits(
              amount.toString(),
              18,
            )

            await contract.transfer(address?.trim(), numberOfTokens)
            await getTokensBalance(account)
            setIsOpenConfirm(false)
            setLoading(false)
            resetData()
            dispatch(
              showToast({
                title:
                  'Transaction has submitted. This progress will take some time to finish.',
              }),
            )
          } catch (error) {
            setLoading(false)
            dispatch(
              showToast({
                title: 'Error, please try again',
              }),
            )
          }
        }
      } catch (error: any) {
        let message = ''

        if (error?.apiStatus === 0) {
          message = error.message
        } else if (error?.message?.includes('rejected transaction')) {
          message = 'User denied transaction signature'
        } else {
          message = 'Something went wrong'
        }

        dispatch(
          showToast({
            title: 'Transfer error',
            message: message || 'Something went wrong',
          }),
        )
      }
      setLoading(false)
      setIsOpenConfirm(false)
    }
  }

  useEffect(() => {
    if (account) {
      getTokensBalance(account)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    if (transferToken && walletOnchain) {
      handleSetTokenBalance(transferToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferToken, walletOnchain])

  const formInvalid =
    !transferToken || !amount || loading || +amount > tokenBalance

  return (
    <div css={[commonClass.containerSmall, styles.wrapper]}>
      <TransferConfirmModal
        token={transferToken}
        amount={amount}
        address={address}
        isLoading={loading}
        isOpen={isOpenConfirm}
        onClose={() => setIsOpenConfirm(false)}
        onConfirm={handleConfirmTransfer}
      />
      {transferToken ? (
        <div css={styles.contentWrapper}>
          <div css={styles.pageHeader}>
            <Link to='/wallet/onchain' css={styles.iconBack}>
              <ArrowLeft size='24' color='#D0E0F7' className='spending' />
            </Link>
            <h3>Send to</h3>
          </div>
          <div css={styles.tokenIconWrapper}>
            <img css={styles.tokenIcon} src={transferToken?.icon} alt='icon' />
          </div>
          <div css={styles.formControl}>
            <FormControl fullWidth>
              <div css={styles.formLabel}>To address</div>
              <OutlinedInput
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                css={styles.inputWrapper}
              />
            </FormControl>
          </div>
          <div css={styles.formControl}>
            <FormControl fullWidth>
              <div css={styles.formLabel}>Amount</div>
              <OutlinedInput
                value={amount}
                onChange={handleChangeAmt}
                css={styles.inputWrapper}
                endAdornment={
                  <div>
                    <span css={styles.tokenType}>{tokenType}</span>
                    <span
                      css={styles.all}
                      onClick={() => setAmount(tokenBalance.toString())}
                    >
                      All
                    </span>
                  </div>
                }
              />
              {transferToken && renderAvailableToken()}
            </FormControl>
          </div>
          <Button
            onClick={() => setIsOpenConfirm(true)}
            css={[commonClass.appButton, styles.confirmBtn]}
            disabled={formInvalid || !transferToken}
          >
            CONFIRM
          </Button>
        </div>
      ) : (
        <div>No available token</div>
      )}
    </div>
  )
}

export default TransferExternal
