import { css } from '@emotion/react'
import { baseColors, tabletWidth } from 'theme'
const styles = {
  wrapper: css`
    max-width: 500px;
    margin: 0 auto;
    padding-bottom: 80px;
    @media only screen and (min-width: ${tabletWidth}px) {
      display: flex;
      max-width: 1000px;
      padding-bottom: 0;
    }
  `,
  section1: css``,
  section2: css`
    color: ${baseColors.neutralColor1};
    margin-top: 16px;
    @media only screen and (min-width: ${tabletWidth}px) {
      flex: 1;
      margin-left: 16px;
      height: calc(100vh - 140px);
      overflow: auto;
      margin-top: 0;
    }
  `,
  desktopInfo: css`
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    background: ${baseColors.imgBg};
  `,
  bgWrapper: css`
    width: calc(100vw - 32px);
    height: calc((100vw - 32px) * 0.6615);
    max-width: 500px;
    max-height: calc(500px * 0.6615);

    @media only screen and (min-width: ${tabletWidth}px) {
      width: 500px;
      height: calc(500px * 0.6615);
    }
  `,
  imgInfoWrapper: css`
    border-radius: 4px;
    overflow: hidden;
    background: ${baseColors.imgBg};
    position: relative;

    @media only screen and (min-width: ${tabletWidth}px) {
      width: 500px;
    }
  `,
  matchInfo: css`
    width: 90%;
    @media only screen and (min-width: ${tabletWidth}px) {
      width: 100%;
    }
  `,
  bgWrapperContent: css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  `,
  shoeImg: css`
    width: 70%;
  `,
  shoeId: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    position: absolute;
    bottom: 0;
  `,

  mintWrapper: css`
    width: 90%;
    max-width: 400px;
    @media only screen and (min-width: ${tabletWidth}px) {
      width: 100%;
      max-width: inherit;
    }
  `,
  mintText: css`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${baseColors.secondaryColor2};
    margin-bottom: 2px;
  `,
  shoeInfoWrapper: css`
    width: 90%;
    @media only screen and (min-width: ${tabletWidth}px) {
      width: 100%;
      max-width: inherit;
    }
  `,

  shoeAttrWrapper: css`
    background: ${baseColors.imgBg};
    border-radius: 4px;
    padding: 16px;
  `,

  attributeButton: css({
    height: 24,
    minWidth: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 18,
  }),
  attributeOutlineButton: css`
    display: flex;
    border: 1.5px solid ${baseColors.primaryColor3};
    color: ${baseColors.primaryColor3};
    background-color: transparent;
    cursor: pointer;
    text-transform: unset;
  `,
  attributeWrap: css({
    marginTop: 10,
  }),

  attributeIcon: css({
    width: 24,
    height: 24,
    borderRadius: 16,
    marginRight: 12,
  }),
  attributeValueLabel: css`
    width: 50px;
    margin-top: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.0125em;
  `,
  progressWrapper: css({
    flex: 1,
    paddingBottom: 5,
  }),
  progressLabel: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  `,
  attributeTextIcon: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1.25px;
  `,
  attrTitle: css`
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.0015em;
  `,

  actionsWrapper: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    @media only screen and (min-width: ${tabletWidth}px) {
      padding: 16px;
      background: ${baseColors.imgBg};
      border-radius: 4px;
      margin-top: 16px;
    }
    @media only screen and (max-width: ${tabletWidth}px) {
      background: ${baseColors.cardBg};
      backdrop-filter: blur(5px);
      border-radius: 4px 4px 0px 0px;
      border-top: 1px solid #2c395b;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 0 16px;
      height: 80px;
      margin: 0;
      max-width: 100%;
    }
    & > span {
      font-weight: 600;
      font-size: 20px;
      line-height: 42px;
      letter-spacing: 0.0015em;
      color: ${baseColors.neutralColor1};
    }
  `,
  confirmBuy: css`
    width: 126px;
    height: 42px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `,
  confirmSell: css`
    width: 100%;
    height: 42px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    max-width: 500px;
    margin: 0 auto;
  `,
  tagStatusWrap: css({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    padding: '4px 8px',
    backgroundColor: baseColors.tagBackground,
    minWidth: 150,
    textAlign: 'center',
    transformOrigin: '65% 0',
    transform: 'translate(-38.3%) rotate(-45deg)',
  }),

  tagStatusText: css`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
  `,

  actionMenu: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
  menuItem: css`
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    .menuItemIcon {
      background-color: #2b3755;
      border-radius: 100px;
      height: 42px;
      width: 42px;
      min-width: 42px;
    }
    svg {
      height: 21px;
      width: 21px;
    }
    span.tlabel {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: ${baseColors.neutralColor1};
      margin-top: 6px;
    }
  `,
}

export default styles
