import { css } from '@emotion/react'
import { baseColors, mobileWidth } from 'theme'
const styles = {
  wrapper: css`
    background-color: ${baseColors.cardBg};
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(95vw - 48px);
    border-radius: 4px;
    max-height: 90vh;
    overflow: auto;

    @media only screen and (min-width: ${mobileWidth}px) {
      width: 400px;
    }
  `,

  bgWrapper: css`
    width: calc(95vw);
    height: calc((95vw) * 0.6615);
    margin: -24px;

    @media only screen and (min-width: ${mobileWidth}px) {
      width: 448px;
      height: calc(448px * 0.6615);
      margin: -24px;
    }
  `,
  bgWrapperContent: css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  `,
  shoeImg: css`
    width: 50%;
    margin-bottom: -30px;
  `,
  shoeId: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
  `,

  modalTitle: css`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${baseColors.neutralColor1};
  `,

  wrapperImg: css`
    height: 600px;
  `,
  mintWrapper: css`
    max-width: 500px;
  `,
  mintText: css`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${baseColors.secondaryColor2};
    margin-bottom: 2px;
  `,

  priceWrapper: css`
    width: calc(100% - 12px);
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${baseColors.neutralColor1};

    img {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  `,

  actions: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 12px);
    max-width: 400px;
  `,
  cancelBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  confirmBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  balanceText: css`
    font-size: 13px;
    color: ${baseColors.neutralColor6}
  `,
}

export default styles
